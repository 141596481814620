import * as Api from '../Domain/Api';
import UpdatePause from './Commands/UpdatePause';
import updatePauseSuccess, {
    type UpdatePausePayload,
} from './Actions/updatePauseSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const updatePause = async (command: UpdatePause) => {
    const payload = await Api.patch<UpdatePausePayload>(
        `${parameters.api.host}/pauses/${command.id}`,
        {
            duration: command.duration,
            label: command.label,
        },
        {
            jwt: JWTStorer.get(),
        },
    );

    return updatePauseSuccess(payload);
};

export default updatePause;
