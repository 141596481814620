export type PauseParameters = {
    id: number;
    duration: number;
    label: string;
};

class Pause {
    id: number;
    duration: number;
    label: string;

    constructor({ id, duration, label }: PauseParameters) {
        this.id = id;
        this.duration = duration;
        this.label = label;

        Object.freeze(this);
    }

    getScheduleType(): string {
        return 'PAUSE';
    }

    computeDuration(): number {
        return this.duration;
    }
}

export default Pause;
