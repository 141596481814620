import * as Api from '../Domain/Api';
import SignupUser from './Commands/SignupUser';
import signupUserSuccess, {
    type SignupUserPayload,
} from './Actions/signupUserSuccess';
import parameters from '../parameters';

const signupUser = async (command: SignupUser) => {
    const payload = await Api.post<SignupUserPayload>(
        `${parameters.api.host}/access/signup`,
        {
            email: command.email,
            password: command.password,
        },
    );

    return signupUserSuccess(payload);
};

export default signupUser;
