import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { keyframes } from '@mui/system';
import { Box, Breadcrumbs, Sheet, Stack, Typography, Link } from '@mui/joy';
import { Home as HomeIcon } from '@mui/icons-material';
import makeSx from '@Guidelines/sx';

const appear = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const sx = makeSx({
    root: (theme) => ({
        paddingBottom: theme.spacing(5),
        animation: `0.25s ${appear}`,
    }),
    content: (theme) => ({
        padding: theme.spacing(2),
    }),
    breadcrumb: (theme) => ({
        margin: 0,
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(0, 2),
        },
    }),
});

type Props = {
    title: React.ReactNode;
    breadcrumbs: Array<{
        label: React.ReactNode;
        to?: string;
    }>;
    children?: React.ReactNode;
};

const PageHead: React.ComponentType<Props> = ({
    title,
    breadcrumbs,
    children,
}) => {
    return (
        <Sheet sx={sx.root} variant="soft" color="neutral">
            <Sheet variant="soft" color="primary" sx={sx.content}>
                <Stack
                    spacing={2}
                    alignItems="center"
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent="space-between"
                >
                    <Typography
                        level="h2"
                        component="h1"
                        textColor="inherit"
                        textAlign="center"
                    >
                        {title}
                    </Typography>
                    {children && <Box>{children}</Box>}
                </Stack>
            </Sheet>
            <Box sx={sx.breadcrumb}>
                <Breadcrumbs>
                    <Link
                        component={RouterLink}
                        to="/_"
                        startDecorator={<HomeIcon />}
                    >
                        Accueil
                    </Link>
                    {breadcrumbs.map(({ label, to }, index) =>
                        to ? (
                            <Link
                                key={index}
                                component={RouterLink}
                                to={`/_${to}`}
                            >
                                {label}
                            </Link>
                        ) : (
                            <Typography key={index}>{label}</Typography>
                        ),
                    )}
                </Breadcrumbs>
            </Box>
        </Sheet>
    );
};

export default PageHead;
