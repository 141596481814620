import * as React from 'react';
import { IconButton, Snackbar, type SvgIconTypeMap } from '@mui/joy';
import { type ContextNotification } from './Context/Provider';
import {
    Dangerous as DangerousIcon,
    ReportProblem as ReportProblemIcon,
    CheckCircleOutline as CheckCircleOutlineIcon,
    Info as InfoIcon,
    Close as CloseIcon,
} from '@mui/icons-material';

type Props = ContextNotification;

const Notification: React.ComponentType<Props> = ({ message, type }) => {
    const [open, setOpen] = React.useState<boolean>(true);

    const onClose = React.useCallback((e: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }, []);

    const onCloseClick = React.useCallback(() => {
        setOpen(false);
    }, []);

    const startDecorator = React.useMemo(() => {
        const iconProps: SvgIconTypeMap['props'] = {
            size: 'md',
        };

        switch (type) {
            case 'danger':
                return <DangerousIcon {...iconProps} />;
            case 'warning':
                return <ReportProblemIcon {...iconProps} />;
            case 'success':
                return <CheckCircleOutlineIcon {...iconProps} />;
            default:
                return <InfoIcon {...iconProps} />;
        }
    }, [type]);

    const endDecorator = React.useMemo(() => {
        return (
            <IconButton color={type} size="sm" onClick={onCloseClick}>
                <CloseIcon />
            </IconButton>
        );
    }, [type, onCloseClick]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            variant="soft"
            open={open}
            color={type}
            autoHideDuration={5000}
            onClose={onClose}
            startDecorator={startDecorator}
            endDecorator={endDecorator}
        >
            {message}
        </Snackbar>
    );
};

export default Notification;
