type DeletePauseOptions = {
    id: number;
};

class DeletePause {
    id: number;

    constructor({ id }: DeletePauseOptions) {
        this.id = id;

        Object.freeze(this);
    }
}

export default DeletePause;
