import * as React from 'react';
import { FormControl, FormLabel, Typography, Input } from '@mui/joy';
import {
    CalendarMonth as CalendarMonthIcon,
    TheaterComedy as TheaterComedyIcon,
} from '@mui/icons-material';
import createShow from '@Application/createShow';
import CreateShow from '@Application/Commands/CreateShow';
import { useDispatch } from '@Stores/index';
import { useNavigate } from 'react-router-dom';
import Modal from '@Guidelines/Modal';

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

const AddShowModal: React.ComponentType<Props> = ({ isOpen, onClose }) => {
    const [name, setName] = React.useState<string>('');
    const [date, setDate] = React.useState<string>('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onNameChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value);
        },
        [],
    );

    const onDateChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setDate(e.target.value);
        },
        [],
    );

    const onModalSubmit = React.useCallback(() => {
        createShow(
            new CreateShow({
                name,
                date: new Date(date),
            }),
        )
            .then(dispatch)
            .then((action) => {
                navigate(`/_/shows/${action.payload.id}`);
            });
    }, [date, dispatch, name, navigate]);

    return (
        <Modal
            title="Créer un nouveau spectacle"
            submitLabel="Créer"
            cancelLabel="Annuler"
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onModalSubmit}
        >
            <FormControl required>
                <FormLabel>
                    <Typography
                        startDecorator={<TheaterComedyIcon />}
                        textColor="inherit"
                    >
                        Nom
                    </Typography>
                </FormLabel>
                <Input
                    type="text"
                    name="show name"
                    autoFocus
                    value={name}
                    onChange={onNameChange}
                    slotProps={{ input: { maxLength: 60 } }}
                />
            </FormControl>
            <FormControl>
                <FormLabel>
                    <Typography
                        startDecorator={<CalendarMonthIcon />}
                        textColor="inherit"
                    >
                        Date
                    </Typography>
                </FormLabel>
                <Input
                    type="datetime-local"
                    name="show date"
                    value={date}
                    onChange={onDateChange}
                />
            </FormControl>
        </Modal>
    );
};

export default AddShowModal;
