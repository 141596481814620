type UpdateTalkOptions = {
    id: number;
    duration: number;
    label: string;
};

class UpdateTalk {
    id: number;
    duration: number;
    label: string;

    constructor({ id, duration, label }: UpdateTalkOptions) {
        this.id = id;
        this.duration = duration;
        this.label = label;

        Object.freeze(this);
    }
}

export default UpdateTalk;
