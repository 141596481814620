import { Theme, useTheme } from '@mui/joy';
import { StyleSheet, Styles } from '@react-pdf/renderer';

type CreateStylesOptions = {
    theme: Theme;
    fontSize: (multiplicator: number) => number;
};

type CreateStyles<T> = (options: CreateStylesOptions) => T;

const useStyles = <T extends Styles>(createStyle: CreateStyles<T>): T => {
    const theme = useTheme();

    const defaultFontSize = 14;

    return StyleSheet.create(
        createStyle({
            theme,
            fontSize: (multiplicator) => defaultFontSize * multiplicator,
        }),
    );
};

export default useStyles;
