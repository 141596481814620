export type SigninUserPayload = {
    token: string;
};

export type SigninUserSuccessAction = {
    type: 'SIGNIN_USER_SUCCESS';
    payload: SigninUserPayload;
};

const signinUserSuccess = (
    payload: SigninUserPayload,
): SigninUserSuccessAction => {
    return {
        type: 'SIGNIN_USER_SUCCESS',
        payload: payload,
    };
};

export default signinUserSuccess;
