export type CreateTalkPayload = {
    id: number;
    showId: number;
    duration: number;
    label: string;
};

export type CreateTalkSuccessAction = {
    type: 'CREATE_TALK_SUCCESS';
    payload: CreateTalkPayload;
};

const createTalkSuccess = (
    payload: CreateTalkPayload,
): CreateTalkSuccessAction => {
    return {
        type: 'CREATE_TALK_SUCCESS',
        payload: payload,
    };
};

export default createTalkSuccess;
