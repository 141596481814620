import * as Api from '../Domain/Api';
import FetchShows from './Queries/FetchShows';
import fetchShowsSuccess, {
    type FetchShowsPayload,
    type FetchShowsSuccessAction,
} from './Actions/fetchShowsSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const fetchShows = async (
    query: FetchShows,
): Promise<FetchShowsSuccessAction> => {
    const payload = await Api.get<FetchShowsPayload>(
        `${parameters.api.host}/shows`,
        {
            jwt: JWTStorer.get(),
        },
    );

    return fetchShowsSuccess(payload);
};

export default fetchShows;
