import * as React from 'react';
import ShowView, { type ShowStep } from '@Stores/Views/Show';
import ImproView from '@Stores/Views/Impro';
import TalkView from '@Stores/Views/Talk';
import PauseView from '@Stores/Views/Pause';
import ScheduleView from '@Stores/Views/Schedule';
import makeSx from '@Guidelines/sx';
import {
    Box,
    Divider,
    Dropdown,
    IconButton,
    ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem,
    Stack,
} from '@mui/joy';
import {
    SportsKabaddi as SportsKabaddiIcon,
    RecordVoiceOver as RecordVoiceOverIcon,
    Pause as PauseIcon,
    Delete as DeleteIcon,
    MoreVert as MoreVertIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon,
    KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
    Inventory2 as Inventory2Icon,
    Archive as ArchiveIcon,
    Unarchive as UnarchiveIcon,
} from '@mui/icons-material';
import {
    ScheduleItemImpro,
    ScheduleItemPause,
    ScheduleItemTalk,
} from './ScheduleItem';
import DndDestination from './ScheduleItem/Dnd/Destination';
import UpdateStepModal from './UpdateStepModal';
import ScheduleStepKey from './ScheduleStepKey';
import { useDispatch } from '@Stores/index';
import createSchedule from '@Application/createSchedule';
import CreateSchedule from '@Application/Commands/CreateSchedule';
import deleteSchedule from '@Application/deleteSchedule';
import DeleteSchedule from '@Application/Commands/DeleteSchedule';
import deleteImpro from '@Application/deleteImpro';
import DeleteImpro from '@Application/Commands/DeleteImpro';
import deleteTalk from '@Application/deleteTalk';
import DeleteTalk from '@Application/Commands/DeleteTalk';
import deletePause from '@Application/deletePause';
import DeletePause from '@Application/Commands/DeletePause';
import updateSchedulePosition from '@Application/updateSchedulePosition';
import UpdateSchedulePosition from '@Application/Commands/UpdateSchedulePosition';

const sx = makeSx({
    schedule: (theme) => ({
        margin: theme.spacing(0, 0, 2),
    }),
});

type Props = {
    show: ShowView;
};

const ShowSchuedule: React.ComponentType<Props> = ({ show }) => {
    const [selectedStep, setSelectedStep] = React.useState<ShowStep | null>(
        null,
    );

    const dispatch = useDispatch();

    const unusedSteps = React.useMemo(() => {
        return show.findUnusedSteps();
    }, [show]);

    const onStepClick = React.useCallback((step: ShowStep) => {
        setSelectedStep(step);
    }, []);

    const onStepMove = React.useCallback(
        (step: ShowStep, position: number | null) => {
            const schedule = show.findSchedule(step);

            if (!schedule) {
                return createSchedule(
                    new CreateSchedule({
                        showId: show.id,
                        itemId: step.id,
                        itemType: step.getScheduleType(),
                        position: position,
                    }),
                ).then(dispatch);
            }

            if (typeof position !== 'number') {
                return deleteSchedule(
                    new DeleteSchedule({
                        id: schedule.id,
                        showId: show.id,
                    }),
                ).then(dispatch);
            }

            const nextPosition =
                position > schedule.position ? position - 1 : position;

            if (nextPosition === schedule.position) return;

            return updateSchedulePosition(
                new UpdateSchedulePosition({
                    id: schedule.id,
                    showId: show.id,
                    position: nextPosition,
                }),
            ).then(dispatch);
        },
        [dispatch, show],
    );

    const onUpdateStepModalClose = React.useCallback(() => {
        setSelectedStep(null);
    }, []);

    const onDetachScheduleClick = React.useCallback(
        (schedule: ScheduleView) => {
            return deleteSchedule(
                new DeleteSchedule({ id: schedule.id, showId: show.id }),
            ).then(dispatch);
        },
        [dispatch, show.id],
    );

    const onDeleteUnusedStepClick = React.useCallback(
        (step: ShowStep) => {
            if (step instanceof ImproView) {
                return deleteImpro(
                    new DeleteImpro({
                        id: step.id,
                    }),
                ).then(dispatch);
            }

            if (step instanceof TalkView) {
                return deleteTalk(
                    new DeleteTalk({
                        id: step.id,
                    }),
                ).then(dispatch);
            }

            if (step instanceof PauseView) {
                return deletePause(
                    new DeletePause({
                        id: step.id,
                    }),
                ).then(dispatch);
            }
        },
        [dispatch],
    );

    const onDeleteScheduleClick = React.useCallback(
        async (schedule: ScheduleView) => {
            const step = show.findStep(schedule);

            await onDetachScheduleClick(schedule);
            await onDeleteUnusedStepClick(step);
        },
        [onDeleteUnusedStepClick, onDetachScheduleClick, show],
    );

    const onAttachUnusedStepClick = React.useCallback(
        (step: ShowStep) => {
            return createSchedule(
                new CreateSchedule({
                    showId: show.id,
                    itemId: step.id,
                    itemType: step.getScheduleType(),
                    position: null,
                }),
            ).then(dispatch);
        },
        [dispatch, show.id],
    );

    const onScheduledStepTopClick = React.useCallback(
        (schedule: ScheduleView) => {
            updateSchedulePosition(
                new UpdateSchedulePosition({
                    id: schedule.id,
                    showId: show.id,
                    position: show.schedules.getFirstPosition(),
                }),
            ).then(dispatch);
        },
        [dispatch, show.schedules, show.id],
    );

    const onScheduledStepUpClick = React.useCallback(
        (schedule: ScheduleView) => {
            updateSchedulePosition(
                new UpdateSchedulePosition({
                    id: schedule.id,
                    showId: show.id,
                    position: schedule.position - 1,
                }),
            ).then(dispatch);
        },
        [dispatch, show.id],
    );

    const onScheduledStepDownClick = React.useCallback(
        (schedule: ScheduleView) => {
            updateSchedulePosition(
                new UpdateSchedulePosition({
                    id: schedule.id,
                    showId: show.id,
                    position: schedule.position + 1,
                }),
            ).then(dispatch);
        },
        [dispatch, show.id],
    );

    const onScheduledStepBottomClick = React.useCallback(
        (schedule: ScheduleView) => {
            updateSchedulePosition(
                new UpdateSchedulePosition({
                    id: schedule.id,
                    showId: show.id,
                    position: show.schedules.getLastPosition(),
                }),
            ).then(dispatch);
        },
        [dispatch, show.schedules, show.id],
    );

    const renderStep = (
        step: ShowStep,
        options: {
            ref?: React.Ref<HTMLElement>;
            startDecorator?: React.ReactNode;
            endDecorator?: React.ReactNode;
        },
    ) => {
        if (step instanceof ImproView) {
            return (
                <ScheduleItemImpro
                    impro={step}
                    onClick={onStepClick}
                    onMove={onStepMove}
                    {...options}
                />
            );
        }

        if (step instanceof TalkView) {
            return (
                <ScheduleItemTalk
                    talk={step}
                    onClick={onStepClick}
                    onMove={onStepMove}
                    {...options}
                />
            );
        }

        if (step instanceof PauseView) {
            return (
                <ScheduleItemPause
                    pause={step}
                    onClick={onStepClick}
                    onMove={onStepMove}
                    {...options}
                />
            );
        }

        return null;
    };

    return (
        <React.Fragment>
            {selectedStep && (
                <UpdateStepModal
                    step={selectedStep}
                    isOpen={!!selectedStep}
                    onClose={onUpdateStepModalClose}
                />
            )}
            <Stack spacing={3}>
                <Box>
                    <Stack direction={{ xs: 'column', lg: 'row' }} spacing={1}>
                        <ScheduleStepKey
                            steps={show.schedules
                                .filterByType('IMPRO')
                                .schedules.map((schedule) =>
                                    show.findStep(schedule),
                                )}
                            label="Temps de jeu"
                            icon={<SportsKabaddiIcon />}
                        />
                        <ScheduleStepKey
                            steps={show.schedules
                                .filterByType('TALK')
                                .schedules.map((schedule) =>
                                    show.findStep(schedule),
                                )}
                            label="Temps de parole"
                            icon={<RecordVoiceOverIcon />}
                        />
                        <ScheduleStepKey
                            steps={show.schedules
                                .filterByType('PAUSE')
                                .schedules.map((schedule) =>
                                    show.findStep(schedule),
                                )}
                            label="Temps de pause"
                            icon={<PauseIcon />}
                        />
                    </Stack>
                </Box>
                <Box>
                    <Stack spacing={1} sx={sx.schedule}>
                        <DndDestination position={1} />
                        {show.schedules
                            .orderByPosition()
                            .schedules.map((schedule) => {
                                const step = show.findStep(schedule);

                                return (
                                    <React.Fragment key={schedule.id}>
                                        {renderStep(step, {
                                            endDecorator: (
                                                <Dropdown>
                                                    <MenuButton
                                                        slots={{
                                                            root: IconButton,
                                                        }}
                                                    >
                                                        <MoreVertIcon />
                                                    </MenuButton>
                                                    <Menu
                                                        placement="auto-start"
                                                        disablePortal
                                                    >
                                                        <MenuItem
                                                            onClick={() =>
                                                                onDetachScheduleClick(
                                                                    schedule,
                                                                )
                                                            }
                                                        >
                                                            <ListItemDecorator>
                                                                <ArchiveIcon />
                                                            </ListItemDecorator>
                                                            Ajouter à la réserve
                                                        </MenuItem>
                                                        <Divider />
                                                        <MenuItem
                                                            disabled={
                                                                schedule.position ===
                                                                show.schedules.getFirstPosition()
                                                            }
                                                            onClick={() =>
                                                                onScheduledStepTopClick(
                                                                    schedule,
                                                                )
                                                            }
                                                        >
                                                            <ListItemDecorator>
                                                                <KeyboardDoubleArrowUpIcon />
                                                            </ListItemDecorator>
                                                            Monter en première
                                                            position
                                                        </MenuItem>
                                                        <MenuItem
                                                            disabled={
                                                                schedule.position ===
                                                                show.schedules.getFirstPosition()
                                                            }
                                                            onClick={() =>
                                                                onScheduledStepUpClick(
                                                                    schedule,
                                                                )
                                                            }
                                                        >
                                                            <ListItemDecorator>
                                                                <KeyboardArrowUpIcon />
                                                            </ListItemDecorator>
                                                            Monter d'une
                                                            position
                                                        </MenuItem>
                                                        <MenuItem
                                                            disabled={
                                                                schedule.position ===
                                                                show.schedules.getLastPosition()
                                                            }
                                                            onClick={() =>
                                                                onScheduledStepDownClick(
                                                                    schedule,
                                                                )
                                                            }
                                                        >
                                                            <ListItemDecorator>
                                                                <KeyboardArrowDownIcon />
                                                            </ListItemDecorator>
                                                            Descendre d'une
                                                            position
                                                        </MenuItem>
                                                        <MenuItem
                                                            disabled={
                                                                schedule.position ===
                                                                show.schedules.getLastPosition()
                                                            }
                                                            onClick={() =>
                                                                onScheduledStepBottomClick(
                                                                    schedule,
                                                                )
                                                            }
                                                        >
                                                            <ListItemDecorator>
                                                                <KeyboardDoubleArrowDownIcon />
                                                            </ListItemDecorator>
                                                            Descendre en
                                                            dernière position
                                                        </MenuItem>
                                                        <Divider />
                                                        <MenuItem
                                                            variant="soft"
                                                            color="danger"
                                                            onClick={() =>
                                                                onDeleteScheduleClick(
                                                                    schedule,
                                                                )
                                                            }
                                                        >
                                                            <ListItemDecorator>
                                                                <DeleteIcon />
                                                            </ListItemDecorator>
                                                            Supprimer
                                                        </MenuItem>
                                                    </Menu>
                                                </Dropdown>
                                            ),
                                        })}
                                        <DndDestination
                                            position={schedule.position + 1}
                                        />
                                    </React.Fragment>
                                );
                            })}
                    </Stack>
                </Box>
                <ScheduleStepKey
                    steps={show.findUnusedSteps()}
                    label="Réserve"
                    icon={<Inventory2Icon />}
                />
                <Box>
                    <Stack spacing={1} sx={sx.schedule}>
                        <DndDestination position={null} />
                        {unusedSteps.map((unusedStep) => (
                            <React.Fragment key={JSON.stringify(unusedStep)}>
                                {renderStep(unusedStep, {
                                    endDecorator: (
                                        <Dropdown>
                                            <MenuButton
                                                slots={{
                                                    root: IconButton,
                                                }}
                                            >
                                                <MoreVertIcon />
                                            </MenuButton>
                                            <Menu
                                                placement="auto-start"
                                                disablePortal
                                            >
                                                <MenuItem
                                                    onClick={() =>
                                                        onAttachUnusedStepClick(
                                                            unusedStep,
                                                        )
                                                    }
                                                >
                                                    <ListItemDecorator>
                                                        <UnarchiveIcon />
                                                    </ListItemDecorator>
                                                    Ajouter au spectacle
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem
                                                    variant="soft"
                                                    color="danger"
                                                    onClick={() =>
                                                        onDeleteUnusedStepClick(
                                                            unusedStep,
                                                        )
                                                    }
                                                >
                                                    <ListItemDecorator>
                                                        <DeleteIcon />
                                                    </ListItemDecorator>
                                                    Supprimer
                                                </MenuItem>
                                            </Menu>
                                        </Dropdown>
                                    ),
                                })}
                            </React.Fragment>
                        ))}
                    </Stack>
                </Box>
            </Stack>
        </React.Fragment>
    );
};

export default ShowSchuedule;
