import * as React from 'react';
import { Box } from '@mui/joy';

import makeSx from '@Guidelines/sx';
import Hero from '@Guidelines/Hero';
import Image, { useCloudinary } from '@Guidelines/Image';
import Navbar from './Navbar';

const sx = makeSx({
    root: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    content: (theme) => ({
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
    }),
});

type Props = {
    children: React.ReactNode;
};

const Layout: React.ComponentType<Props> = ({ children }) => {
    const cloudinary = useCloudinary();

    return (
        <Hero
            backgroundImage={
                <Image
                    src={cloudinary.image('theater-min.jpg', {
                        transformation: 'Hero',
                    })}
                    alt="Theatre curtain"
                    cover
                />
            }
        >
            <Box sx={sx.root}>
                <Navbar />
                <Box sx={sx.content}>{children}</Box>
            </Box>
        </Hero>
    );
};

export default Layout;
