type CreateShowOptions = {
    name: string;
    date: Date;
};

class CreateShow {
    name: string;
    date: Date;

    constructor({ name, date }: CreateShowOptions) {
        this.name = name;
        this.date = date;

        Object.freeze(this);
    }
}

export default CreateShow;
