type FetchShowPayload = {
    id: number;
    name: string;
    date: string | null;
    impros: Array<{
        id: number;
        duration: number;
        presentation: number;
        debrief: number;
        players: number | null;
        theme: string | null;
        infos: string | null;
        category: {
            id: number;
            name: string;
            userId: number | null;
        };
        type: string;
    }>;
    talks: Array<{
        id: number;
        duration: number;
        label: string;
    }>;
    pauses: Array<{
        id: number;
        duration: number;
        label: string;
    }>;
    schedules: Array<{
        id: number;
        position: number;
        itemType: string;
        itemId: number;
    }>;
};

type ComputedFetchShowPayload = Omit<FetchShowPayload, 'date'> & {
    date: Date | null;
};

export type FetchShowsPayload = Array<FetchShowPayload>;

type ComputedFetchShowsPayload = Array<ComputedFetchShowPayload>;

const computePayload = (
    payload: FetchShowsPayload,
): ComputedFetchShowsPayload => {
    return payload.map((item) => ({
        ...item,
        date: item.date ? new Date(item.date) : null,
    }));
};

export type FetchShowsSuccessAction = {
    type: 'FETCH_SHOWS_SUCCESS';
    payload: ComputedFetchShowsPayload;
};

const fetchShowsSuccess = (
    payload: FetchShowsPayload,
): FetchShowsSuccessAction => {
    return {
        type: 'FETCH_SHOWS_SUCCESS',
        payload: computePayload(payload),
    };
};

export default fetchShowsSuccess;
