type DeleteTalkOptions = {
    id: number;
};

class DeleteTalk {
    id: number;

    constructor({ id }: DeleteTalkOptions) {
        this.id = id;

        Object.freeze(this);
    }
}

export default DeleteTalk;
