import * as React from 'react';
import useStore from './Context/useStore';
import debug from '@Guidelines/debug';

const debugRegisterStore = debug('registerStore');

type Store = {
    reducer: React.Reducer<any, any>;
    initialState: any;
};

const stores: Map<string, Store> = new Map();

const register = <
    S extends React.ReducerState<any>,
    A extends React.ReducerAction<any>,
>(
    key: string,
    reducer: React.Reducer<S, A>,
    initialState: S,
): (() => S) => {
    if (!stores.get(key)) {
        debugRegisterStore(key);

        stores.set(key, {
            reducer,
            initialState,
        });
    }

    return () => {
        return useStore<S>(key, initialState);
    };
};

export default register;
export { stores };
