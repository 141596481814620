import * as React from 'react';
import TalkView from '@Stores/Views/Talk';
import {
    ScheduleItemBlock,
    ScheduleItemLine,
    type ScheduleItemBlockProps,
} from './Item';

type Props = {
    talk: TalkView;
    onClick: (talk: TalkView) => void;
    onMove: (talk: TalkView, position: number | null) => void;
} & Omit<ScheduleItemBlockProps, 'onClick' | 'onMove' | 'children'>;

const ScheduleItemTalk: React.ComponentType<Props> = ({
    talk,
    startDecorator,
    endDecorator,
    onClick,
    onMove,
}) => {
    const onBlockClick = React.useCallback(() => {
        onClick(talk);
    }, [talk, onClick]);

    const onBlockMove = React.useCallback(
        (position: number | null) => {
            onMove(talk, position);
        },
        [talk, onMove],
    );

    return (
        <ScheduleItemBlock
            onClick={onBlockClick}
            onMove={onBlockMove}
            startDecorator={startDecorator}
            endDecorator={endDecorator}
        >
            <ScheduleItemLine
                variant="soft"
                color="primary"
                size="md"
                duration={talk.duration}
            >
                {talk.label}
            </ScheduleItemLine>
        </ScheduleItemBlock>
    );
};

export default ScheduleItemTalk;
