type SigninUserOptions = {
    email: string;
    password: string;
};

class SigninUser {
    email: string;
    password: string;

    constructor({ email, password }: SigninUserOptions) {
        this.email = email;
        this.password = password;

        Object.freeze(this);
    }
}

export default SigninUser;
