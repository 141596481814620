import * as React from 'react';
import { Button, Stack } from '@mui/joy';
import { Add as AddIcon } from '@mui/icons-material';
import List from './List';
import { useDispatch, useWaitForState } from '@Stores/index';
import useShowsStore from '@Stores/Stores/shows';
import fetchShows from '@Application/fetchShows';
import FetchShows from '@Application/Queries/FetchShows';
import Loader from '@Guidelines/Loader';
import Page from '../Page';
import PageHead from '../PageHead';
import PageBody from '../PageBody';
import PageContent from '../PageContent';
import AddShowModal from './AddShowModal';

type Props = {};

const Shows: React.ComponentType<Props> = () => {
    const [isAddShowModalOpen, setIsAddShowModalOpen] = React.useState(false);

    const showsStore = useShowsStore();
    const dispatch = useDispatch();

    const shows = useWaitForState({
        selector: () => showsStore.shows,
        check: (shows) => !!shows,
        run: () => {
            fetchShows(new FetchShows()).then(dispatch);
        },
    });

    const onAddShowButtonClick = React.useCallback(() => {
        setIsAddShowModalOpen(true);
    }, []);

    const onAddShowModalClose = React.useCallback(() => {
        setIsAddShowModalOpen(false);
    }, []);

    if (!shows) return <Loader />;

    return (
        <Page>
            <PageHead
                title="Vos spectacles"
                breadcrumbs={[
                    {
                        label: 'Spectacles',
                    },
                ]}
            >
                <Button
                    variant="solid"
                    color="primary"
                    startDecorator={<AddIcon />}
                    onClick={onAddShowButtonClick}
                    size="lg"
                >
                    Nouveau spectacle
                </Button>
            </PageHead>
            <PageBody>
                <PageContent>
                    <Stack spacing={5}>
                        <List
                            title="Les spectacles à venir"
                            shows={shows.filterFutureShows().orderByDate()}
                        ></List>
                        <List
                            title="Les spectacles en attente"
                            shows={shows.filterWaitingShows().orderById(false)}
                        ></List>
                        <List
                            title="Les spectacles passés"
                            shows={shows.filterPastShows().orderByDate(false)}
                        ></List>
                    </Stack>
                </PageContent>
            </PageBody>
            <AddShowModal
                isOpen={isAddShowModalOpen}
                onClose={onAddShowModalClose}
            />
        </Page>
    );
};

export default Shows;
