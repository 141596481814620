export type FetchCategoriesPayload = Array<{
    id: number;
    name: string;
    userId: number | null;
}>;

export type FetchCategoriesSuccessAction = {
    type: 'FETCH_CATEGORIES_SUCCESS';
    payload: FetchCategoriesPayload;
};

const fetchCategoriesSuccess = (
    payload: FetchCategoriesPayload,
): FetchCategoriesSuccessAction => {
    return {
        type: 'FETCH_CATEGORIES_SUCCESS',
        payload: payload,
    };
};

export default fetchCategoriesSuccess;
