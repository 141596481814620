export type UpdateTalkPayload = {
    id: number;
    showId: number;
    duration: number;
    label: string;
};

export type UpdateTalkSuccessAction = {
    type: 'UPDATE_TALK_SUCCESS';
    payload: UpdateTalkPayload;
};

const updateTalkSuccess = (
    payload: UpdateTalkPayload,
): UpdateTalkSuccessAction => {
    return {
        type: 'UPDATE_TALK_SUCCESS',
        payload: payload,
    };
};

export default updateTalkSuccess;
