import debug from '@Guidelines/debug';

const debugPassword = debug('computePasswordStrength');

const passwordStrengths = {
    VERY_WEAK: 25,
    WEAK: 50,
    STRONG: 70,
    VERY_STRONG: 90,
};

const computeStrength = (password: string) => {
    const percentages = {
        length: testLength(password),
        lower: testLowerCase(password),
        upper: testUpperCase(password),
        number: testNumbers(password),
        special: testSpecial(password),
    };

    const values = Object.values(percentages);

    const sum = values.reduce((acc, percentage) => acc + percentage, 0);
    const strength = sum / values.length;

    debugPassword({ password, strength, percentages, passwordStrengths });

    return strength;
};

const testLength = (password: string) => {
    return percentage(password.length, 12);
};

const testLowerCase = (password: string) => {
    const matches = match(password, /[a-z]/g);

    return percentage(matches.length, 8);
};

const testUpperCase = (password: string) => {
    const matches = match(password, /[A-Z]/g);

    return percentage(matches.length, 2);
};

const testNumbers = (password: string) => {
    const matches = match(password, /[0-9]/g);

    return percentage(matches.length, 2);
};

const testSpecial = (password: string) => {
    const matches = match(password, /[^0-9,a-z,A-Z]/g);

    return percentage(matches.length, 2);
};

const match = (password: string, regex: RegExp) => {
    return Array.from(password.match(regex) ?? []);
};

const percentage = (value: number, max: number) => {
    const percentage = (value * 100) / max;

    return Math.min(100, percentage);
};

export default computeStrength;
export { passwordStrengths };
