export type SignoutUserSuccessAction = {
    type: 'SIGNOUT_USER_SUCCESS';
};

const signoutUserSuccess = (): SignoutUserSuccessAction => {
    return {
        type: 'SIGNOUT_USER_SUCCESS',
    };
};

export default signoutUserSuccess;
