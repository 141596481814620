import * as React from 'react';
import {
    Modal as ModalRoot,
    ModalDialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stack,
    Typography,
    CardOverflow,
    Box,
    ModalClose,
} from '@mui/joy';
import { keyframes } from '@mui/system';
import makeSx from '@Guidelines/sx';
import Image, { useCloudinary } from '@Guidelines/Image';

const appearBackdrop = keyframes`
    0% {
        opacity: 0;
        backdrop-filter: blur(0);
    }
    100% {
        opacity: 1;
        backdrop-filter: blur(8px);
    }
`;

const appearDialogCenter = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const appearDialogBottom = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
`;

const sx = makeSx({
    backdrop: {
        animation: `0.25s ${appearBackdrop}`,
    },
    dialog: (theme) => ({
        animation: `0.25s ${appearDialogCenter}`,
        [theme.breakpoints.only('xs')]: {
            animation: `0.25s ${appearDialogBottom}`,
            top: 'unset',
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
            transform: 'none',
            maxWidth: 'unset',
        },
    }),
    logo: {
        height: '92px',
        width: '92px',
        marginTop: `calc(-46px - 1.25rem)`,
    },
});

type Props = {
    title: React.ReactNode;
    submitLabel?: string;
    cancelLabel?: string;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
    actions?: React.ReactNode;
    children: React.ReactNode;
};

const Modal: React.ComponentType<Props> = ({
    title,
    submitLabel,
    cancelLabel,
    isOpen,
    onClose,
    onSubmit,
    actions,
    children,
}) => {
    const cloudinary = useCloudinary();

    const onFormSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            onSubmit();
        },
        [onSubmit],
    );

    const onFormReset = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            onClose();
        },
        [onClose],
    );

    return (
        <ModalRoot
            open={isOpen}
            onClose={onClose}
            slotProps={{
                backdrop: {
                    sx: sx.backdrop,
                },
            }}
        >
            <form onSubmit={onFormSubmit} onReset={onFormReset}>
                <ModalDialog sx={sx.dialog} variant="soft">
                    <CardOverflow variant="soft" color="primary">
                        <ModalClose variant="plain" />
                        <DialogTitle>
                            <Stack spacing={2} alignItems="center" flexGrow={1}>
                                <Box>
                                    <Box sx={sx.logo}>
                                        <Image
                                            src={cloudinary.image(
                                                '/logo/round-min.png',
                                            )}
                                            alt="improkit logo"
                                        />
                                    </Box>
                                </Box>
                                <Typography
                                    textAlign="center"
                                    level="h3"
                                    textColor="inherit"
                                >
                                    {title}
                                </Typography>
                                {actions}
                            </Stack>
                        </DialogTitle>
                    </CardOverflow>
                    <DialogContent>
                        <Stack spacing={3}>{children}</Stack>
                    </DialogContent>
                    <CardOverflow variant="soft">
                        <DialogActions orientation="horizontal">
                            <Button
                                type="reset"
                                variant="outlined"
                                color="neutral"
                            >
                                {cancelLabel}
                            </Button>
                            <Button type="submit" fullWidth>
                                {submitLabel}
                            </Button>
                        </DialogActions>
                    </CardOverflow>
                </ModalDialog>
            </form>
        </ModalRoot>
    );
};

export default Modal;
