export type CreateShowPayload = {
    id: number;
    name: string;
    date: string | null;
    impros: Array<{
        id: number;
        duration: number;
        presentation: number;
        debrief: number;
        players: number | null;
        theme: string | null;
        infos: string | null;
        category: {
            id: number;
            name: string;
            userId: number | null;
        };
        type: string;
    }>;
    talks: Array<{
        id: number;
        duration: number;
        label: string;
    }>;
    pauses: Array<{
        id: number;
        duration: number;
        label: string;
    }>;
    schedules: Array<{
        id: number;
        position: number;
        itemType: string;
        itemId: number;
    }>;
};

type ComputedCreateShowPayload = Omit<CreateShowPayload, 'date'> & {
    date: Date | null;
};

const computePayload = (
    payload: CreateShowPayload,
): ComputedCreateShowPayload => {
    return {
        ...payload,
        date: payload.date ? new Date(payload.date) : null,
    };
};

export type CreateShowSuccessAction = {
    type: 'CREATE_SHOW_SUCCESS';
    payload: ComputedCreateShowPayload;
};

const createShowSuccess = (
    payload: CreateShowPayload,
): CreateShowSuccessAction => {
    return {
        type: 'CREATE_SHOW_SUCCESS',
        payload: computePayload(payload),
    };
};

export default createShowSuccess;
