import Impro from './Impro';

class Impros {
    impros: Array<Impro>;

    constructor(impros: Array<Impro>) {
        this.impros = impros;

        Object.freeze(this);
    }

    findById(id: number): Impro {
        const impro = this.impros.find((impro) => impro.id === id);

        if (!impro) {
            throw new Error(`Impro with id ${id} not found`);
        }

        return impro;
    }

    addImpro(impro: Impro): Impros {
        return new Impros(this.impros.concat([impro]));
    }

    updateImpro(impro: Impro): Impros {
        return new Impros(
            this.impros.map((existingImpro) =>
                existingImpro.id === impro.id ? impro : existingImpro,
            ),
        );
    }

    removeImpro(id: number): Impros {
        return new Impros(this.impros.filter((impro) => impro.id !== id));
    }
}

export default Impros;
