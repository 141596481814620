import * as React from 'react';
import { Page } from '@react-pdf/renderer';
import useStyles from './useStyles';

type Props = {
    front: React.ReactNode;
    back: React.ReactNode;
};

const FrontBackPage: React.ComponentType<Props> = ({ front, back }) => {
    const styles = useStyles(({ theme, fontSize }) => ({
        page: {
            flexDirection: 'row',
            backgroundColor: theme.palette.common.white,
            flexWrap: 'wrap',
            fontFamily: 'Inter',
            fontSize: fontSize(1),
            color: theme.palette.neutral[800],
        },
        pageFront: {},
        pageBack: {
            flexDirection: 'row-reverse',
            transform: 'scale(-1)',
        },
    }));

    return (
        <React.Fragment>
            <Page
                style={[styles.page, styles.pageFront]}
                orientation="landscape"
            >
                {front}
            </Page>
            <Page
                style={[styles.page, styles.pageBack]}
                orientation="landscape"
            >
                {back}
            </Page>
        </React.Fragment>
    );
};

export default FrontBackPage;
