import * as React from 'react';
import Context from '.';

const useDispatch = () => {
    const context = React.useContext(Context);

    if (!context) {
        throw new Error('useDispatch must be used within a Store');
    }

    return context.dispatch;
};

export default useDispatch;
