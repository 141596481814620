import * as Api from '../Domain/Api';
import DeleteSchedule from './Commands/DeleteSchedule';
import deleteScheduleSuccess, {
    type DeleteSchedulePayload,
} from './Actions/deleteScheduleSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const deleteSchedule = async (command: DeleteSchedule) => {
    const payload = await Api.del<DeleteSchedulePayload>(
        `${parameters.api.host}/shows/${command.showId}/schedules/${command.id}`,
        {
            jwt: JWTStorer.get(),
        },
    );

    return deleteScheduleSuccess(payload);
};

export default deleteSchedule;
