export type ScheduleParameters = {
    id: number;
    itemType: string;
    itemId: number;
    position: number;
};

class Schedule {
    id: number;
    position: number;
    itemType: string;
    itemId: number;

    constructor({ id, position, itemType, itemId }: ScheduleParameters) {
        this.id = id;
        this.position = position;
        this.itemType = itemType;
        this.itemId = itemId;

        Object.freeze(this);
    }
}

export default Schedule;
