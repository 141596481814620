import * as React from 'react';
import { useDrag } from 'react-dnd';
import { Box, IconButton, Sheet, Stack } from '@mui/joy';
import { DragIndicator as DragIndicatorIcon } from '@mui/icons-material';
import makeSx, { combineSX } from '@Guidelines/sx';

const sx = makeSx({
    root: (theme) => ({
        position: 'relative',
        zIndex: 1,
        '&:hover': {
            zIndex: 2,
        },
    }),
    dragged: (theme) => ({}),
    content: (theme) => ({
        flex: '1 1 auto',
        padding: 0,
        margin: 0,
        background: 'transparent',
        transition: 'all 0.1s',
        '&:hover': {
            background: theme.palette.background.backdrop,
            padding: theme.spacing(1),
            margin: theme.spacing(-1),
            borderRadius: theme.radius.lg,
            boxShadow: theme.shadow.lg,
        },
    }),
    contentDragged: (theme) => ({
        opacity: 0.2,
    }),
    lines: {
        flex: '1 1 auto',
        cursor: 'pointer',
    },
    dragButton: {
        cursor: 'grab',
    },
    decorator: (theme) => ({
        background: theme.palette.background.surface,
        borderRadius: theme.radius.sm,
        display: 'flex',
    }),
});

export type Props = {
    onClick: () => void;
    onMove: (position: number | null) => void;
    startDecorator?: React.ReactNode;
    endDecorator?: React.ReactNode;
    children: React.ReactNode;
};

const ScheduleItemBlock: React.ComponentType<Props> = ({
    onClick,
    onMove,
    startDecorator,
    endDecorator,
    children,
}) => {
    const [collected, dragRef, previewRef] = useDrag({
        type: 'scheduleItem',
        end(draggedItem, monitor) {
            const dropResult = monitor.getDropResult();

            if (!dropResult || typeof dropResult !== 'object') return;

            const { position } = dropResult as {
                position?: any;
            };

            if (position === null || typeof position === 'number') {
                onMove(position);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            didDrop: monitor.didDrop(),
        }),
    });

    return (
        <Box sx={combineSX(sx.root, collected.isDragging ? sx.dragged : null)}>
            <Stack
                direction="row"
                spacing={1}
                sx={combineSX(
                    sx.content,
                    collected.isDragging ? sx.contentDragged : null,
                )}
                ref={previewRef}
            >
                <Sheet sx={sx.decorator}>
                    <IconButton ref={dragRef} sx={sx.dragButton}>
                        <DragIndicatorIcon />
                    </IconButton>
                </Sheet>
                {startDecorator && (
                    <Sheet sx={sx.decorator}>{startDecorator}</Sheet>
                )}
                <Stack
                    spacing={1}
                    onClick={onClick}
                    sx={sx.lines}
                    data-skip-inverted-colors
                >
                    {children}
                </Stack>
                {endDecorator && (
                    <Sheet sx={sx.decorator}>{endDecorator}</Sheet>
                )}
            </Stack>
        </Box>
    );
};

export default ScheduleItemBlock;
