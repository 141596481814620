import SignoutUser from './Commands/SignoutUser';
import signoutUserSuccess from './Actions/signoutUserSuccess';
import * as JWTStorer from '../Domain/JWTStorer';

const signoutUser = async (command: SignoutUser) => {
    JWTStorer.remove();

    return signoutUserSuccess();
};

export default signoutUser;
