import * as Fetcher from '../Infra/Fetcher';

const ApiError = class extends Error {
    status: number;

    constructor(message: string, data: { status: number }) {
        super(message);

        this.name = 'ApiError';
        this.status = data.status;
    }
};

type Options = {
    jwt?: string;
};

const get = <T>(url: string, options?: Options): Promise<T> => {
    return call(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${options?.jwt}`,
        },
    });
};

const post = <T>(url: string, body: Object, options?: Options): Promise<T> => {
    return call(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${options?.jwt}`,
        },
        body: JSON.stringify(body),
    });
};

const patch = <T>(url: string, body: Object, options?: Options): Promise<T> => {
    return call(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${options?.jwt}`,
        },
        body: JSON.stringify(body),
    });
};

const put = <T>(url: string, body: Object, options?: Options): Promise<T> => {
    return call(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${options?.jwt}`,
        },
        body: JSON.stringify(body),
    });
};

const del = <T>(url: string, options?: Options): Promise<T> => {
    return call(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${options?.jwt}`,
        },
    });
};

const call = async <T>(info: RequestInfo, init?: RequestInit): Promise<T> => {
    const response = await Fetcher.concurrencyFetch(info, init);

    if (!response.ok) {
        throw new ApiError(response.statusText, {
            status: response.status,
        });
    }

    return await response.json();
};

export { get, post, put, patch, del };
