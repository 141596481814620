import * as Api from '../Domain/Api';
import FetchCategories from './Queries/FetchCategories';
import fetchCategoriesSuccess, {
    type FetchCategoriesPayload,
    type FetchCategoriesSuccessAction,
} from './Actions/fetchCategoriesSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const fetchCategories = async (
    query: FetchCategories,
): Promise<FetchCategoriesSuccessAction> => {
    const payload = await Api.get<FetchCategoriesPayload>(
        `${parameters.api.host}/categories`,
        {
            jwt: JWTStorer.get(),
        },
    );

    return fetchCategoriesSuccess(payload);
};

export default fetchCategories;
