export type DeletePausePayload = {
    id: number;
    showId: number;
};

export type DeletePauseSuccessAction = {
    type: 'DELETE_PAUSE_SUCCESS';
    payload: DeletePausePayload;
};

const deletePauseSuccess = (
    payload: DeletePausePayload,
): DeletePauseSuccessAction => {
    return {
        type: 'DELETE_PAUSE_SUCCESS',
        payload: payload,
    };
};

export default deletePauseSuccess;
