import * as React from 'react';
import { NotificationsProvider, Notification } from '@Guidelines/Notification';
import Store from '@Stores/index';
import Pages from './Pages';
import ErrorBoundary from './ErrorBoundary';

type Props = {};

const UI: React.ComponentType<Props> = () => {
    return (
        <Store>
            <NotificationsProvider NotificationComponent={Notification}>
                <ErrorBoundary>
                    <Pages />
                </ErrorBoundary>
            </NotificationsProvider>
        </Store>
    );
};

export default UI;
