type CreatePauseOptions = {
    showId: number;
    duration: number;
    label: string;
};

class CreatePause {
    showId: number;
    duration: number;
    label: string;

    constructor({ showId, duration, label }: CreatePauseOptions) {
        this.showId = showId;
        this.duration = duration;
        this.label = label;

        Object.freeze(this);
    }
}

export default CreatePause;
