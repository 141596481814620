import * as React from 'react';
import ShowView from '@Stores/Views/Show';
import { useDispatch } from '@Stores/index';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardOverflow,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Typography,
} from '@mui/joy';
import {
    CalendarMonth as CalendarMonthIcon,
    TheaterComedy as TheaterComedyIcon,
} from '@mui/icons-material';
import updateShowDetails from '@Application/updateShowDetails';
import UpdateShowDetails from '@Application/Commands/UpdateShowDetails';
import { useNotifications } from '@Guidelines/Notification';
import makeSx from '@Guidelines/sx';

const sx = makeSx({
    root: {
        height: '100%',
    },
    card: {
        height: '100%',
    },
});

type Props = {
    show: ShowView;
};

const ShowDetail: React.ComponentType<Props> = ({ show }) => {
    const dispatch = useDispatch();
    const notify = useNotifications();

    const [name, setName] = React.useState<string>(show.name);
    const [date, setDate] = React.useState<string>(() => {
        const { date } = show;

        if (!date) return '';

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    });

    const onNameChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
        },
        [],
    );

    const onDateChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setDate(event.target.value);
        },
        [],
    );

    const onFormSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            updateShowDetails(
                new UpdateShowDetails({
                    id: show.id,
                    name,
                    date: new Date(date),
                }),
            )
                .then(dispatch)
                .then(() => {
                    notify.success(
                        `Le spectacle a été mis à jour avec succès !`,
                    );
                })
                .catch(() => {
                    notify.error(
                        'Une erreur est survenue lors de la mise à jour du spectacle.',
                    );
                });
        },
        [show.id, date, name, dispatch, notify],
    );

    return (
        <Box component="form" onSubmit={onFormSubmit} sx={sx.root}>
            <Card variant="soft" sx={sx.card}>
                <CardOverflow variant="soft" color="primary">
                    <CardContent>
                        <Typography level="h4" textColor="inherit">
                            Les détails du spectacle
                        </Typography>
                    </CardContent>
                </CardOverflow>
                <CardContent>
                    <Stack spacing={3}>
                        <FormControl required>
                            <FormLabel>
                                <Typography
                                    startDecorator={<TheaterComedyIcon />}
                                    textColor="inherit"
                                >
                                    Name
                                </Typography>
                            </FormLabel>
                            <Input
                                type="text"
                                name="show name"
                                value={name}
                                onChange={onNameChange}
                                slotProps={{ input: { maxLength: 60 } }}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>
                                <Typography
                                    startDecorator={<CalendarMonthIcon />}
                                    textColor="inherit"
                                >
                                    Date
                                </Typography>
                            </FormLabel>
                            <Input
                                type="datetime-local"
                                name="show date"
                                value={date}
                                onChange={onDateChange}
                            />
                        </FormControl>
                    </Stack>
                </CardContent>
                <CardActions>
                    <Button type="submit">Enregistrer</Button>
                </CardActions>
            </Card>
        </Box>
    );
};

export default ShowDetail;
