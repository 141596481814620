const get = (key: string): string | null => {
    return window.localStorage.getItem(key);
};

const set = (key: string, value: string): void => {
    return window.localStorage.setItem(key, value);
};

const remove = (key: string): void => {
    return window.localStorage.removeItem(key);
};

export { get, set, remove };
