import * as React from 'react';
import { LinearProgress } from '@mui/joy';
import createSx from '@Guidelines/sx';
import { keyframes } from '@mui/system';

const delay = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const sx = createSx({
    root: {
        position: 'fixed',
        top: 0,
        width: '100%',
        animation: `${delay} 1s`,
    },
});

type Props = {};

const Loader: React.ComponentType<Props> = () => {
    return <LinearProgress sx={sx.root} size="sm" variant="plain" />;
};

export default Loader;
