import * as React from 'react';
import { Box } from '@mui/joy';
import { makeSx, combineSX } from '@Guidelines/sx';
import debug from '@Guidelines/debug';

const debugImage = debug('Image');

const sx = makeSx({
    root: {
        position: 'relative',
        width: '100%',
        height: '100%',
        opacity: 0,
    },
    rootLoaded: {
        opacity: 1,
        transition: 'all 0.25s',
    },
    img: () => ({
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectPosition: '50% 50%',
    }),
});

type Props = {
    src: string;
    alt: string;
    cover?: boolean;
};

const ImageBase: React.ComponentType<Props> = ({ src, alt, cover }) => {
    const [srcLoaded, setSrcLoaded] = React.useState<string>();

    const isLoaded = React.useMemo(() => srcLoaded === src, [src, srcLoaded]);

    React.useEffect(() => {
        if (isLoaded || srcLoaded === undefined) return;

        debugImage('reset', srcLoaded);

        setSrcLoaded(undefined);
    }, [isLoaded, srcLoaded]);

    const onLoad = React.useCallback(() => {
        debugImage('onLoad', src);

        setSrcLoaded(src);
    }, [src]);

    return (
        <Box sx={combineSX(sx.root, isLoaded ? sx.rootLoaded : null)}>
            <Box
                onLoad={onLoad}
                component="img"
                src={src}
                sx={combineSX(sx.img, {
                    objectFit: cover === true ? 'cover' : 'contain',
                })}
                alt={alt}
                loading="lazy"
            />
        </Box>
    );
};

export default ImageBase;
