import * as React from 'react';
import { keyframes } from '@mui/system';
import makeSx from '@Guidelines/sx';
import { Sheet } from '@mui/joy';

const appear = keyframes`
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const sx = makeSx({
    root: (theme) => ({
        position: 'relative',
        margin: 0,
        marginTop: theme.spacing(-5),
        padding: 0,
        flex: '1 1 auto',
        overflow: 'auto',
        height: '350px',
        animation: `0.35s ${appear}`,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            overflow: 'hidden',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(2),
            borderRadius: theme.radius.md,
            boxShadow: theme.shadow.md,
        },
    }),
});

type Props = {
    children: React.ReactNode;
};

const PageBody: React.ComponentType<Props> = ({ children }) => {
    return (
        <Sheet variant="plain" sx={sx.root}>
            {children}
        </Sheet>
    );
};

export default PageBody;
