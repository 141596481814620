import * as React from 'react';

export type Shape = {
    dispatch: <A>(action: A) => A;
    state: Record<string, any>;
};

const Context = React.createContext<Shape | undefined>(undefined);

export default Context;
