import Category, { type CategoryParameters } from './Category';

export type ImproParameters = {
    id: number;
    duration: number;
    presentation: number;
    debrief: number;
    players: number | null;
    theme: string | null;
    infos: string | null;
    category: CategoryParameters | null;
    type: string;
};

class Impro {
    id: number;
    duration: number;
    presentation: number;
    debrief: number;
    players: number | null;
    theme: string | null;
    infos: string | null;
    category: Category | null;
    type: string;

    constructor({
        id,
        duration,
        presentation,
        debrief,
        players,
        theme,
        infos,
        category,
        type,
    }: ImproParameters) {
        this.id = id;
        this.duration = duration;
        this.presentation = presentation;
        this.debrief = debrief;
        this.players = players;
        this.theme = theme;
        this.infos = infos;
        this.category = category ? new Category(category) : null;
        this.type = type;

        Object.freeze(this);
    }

    getScheduleType(): string {
        return 'IMPRO';
    }

    getMultiplicator(): number {
        return this.type === 'COMPARATIVE' ? 2 : 1;
    }

    computeDuration(): number {
        return (
            this.duration * this.getMultiplicator() +
            this.presentation +
            (this.debrief || 0)
        );
    }
}

export default Impro;
