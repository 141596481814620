import * as React from 'react';
import Page from '../Page';
import PageHead from '../PageHead';
import PageBody from '../PageBody';
import PageContent from '../PageContent';

type Props = {};

const Profile: React.ComponentType<Props> = () => {
    return (
        <Page>
            <PageHead
                title="Votre profil"
                breadcrumbs={[{ label: 'Profil' }]}
            ></PageHead>
            <PageBody>
                <PageContent>{null}</PageContent>
            </PageBody>
        </Page>
    );
};

export default Profile;
