import * as Api from '../Domain/Api';
import CreateSchedule from './Commands/CreateSchedule';
import createScheduleSuccess, {
    type CreateSchedulePayload,
} from './Actions/createScheduleSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const createSchedule = async (command: CreateSchedule) => {
    const payload = await Api.post<CreateSchedulePayload>(
        `${parameters.api.host}/shows/${command.showId}/schedules`,
        {
            itemId: command.itemId,
            itemType: command.itemType,
            position: command.position,
        },
        {
            jwt: JWTStorer.get(),
        },
    );

    return createScheduleSuccess(payload);
};

export default createSchedule;
