import * as React from 'react';
import { type SigninUserSuccessAction } from '@Application/Actions/signinUserSuccess';
import { type SignoutUserSuccessAction } from '@Application/Actions/signoutUserSuccess';
import { type FetchUserSuccessAction } from '@Application/Actions/fetchUserSuccess';
import register from '../register';
import UserView from '../Views/User';

type Action =
    | SigninUserSuccessAction
    | SignoutUserSuccessAction
    | FetchUserSuccessAction;

type State = {
    user?: UserView;
};

const initialState: State = {};

const store: React.Reducer<State, Action> = (state, action): State => {
    switch (action.type) {
        case 'FETCH_USER_SUCCESS':
            return {
                ...state,
                user: new UserView(action.payload),
            };
        case 'SIGNIN_USER_SUCCESS':
        case 'SIGNOUT_USER_SUCCESS':
            return initialState;
        default:
            return state;
    }
};

const useStore = register('user', store, initialState);

export default useStore;
