export type DeleteTalkPayload = {
    id: number;
    showId: number;
};

export type DeleteTalkSuccessAction = {
    type: 'DELETE_TALK_SUCCESS';
    payload: DeleteTalkPayload;
};

const deleteTalkSuccess = (
    payload: DeleteTalkPayload,
): DeleteTalkSuccessAction => {
    return {
        type: 'DELETE_TALK_SUCCESS',
        payload: payload,
    };
};

export default deleteTalkSuccess;
