type CreateImproOptions = {
    showId: number;
    duration: number;
    presentation: number;
    debrief: number;
    players: number | null;
    type: string;
    theme: string | null;
    infos: string | null;
    categoryId: number | null;
};

class CreateImpro {
    showId: number;
    duration: number;
    presentation: number;
    debrief: number;
    players: number | null;
    type: string;
    theme: string | null;
    infos: string | null;
    categoryId: number | null;

    constructor({
        showId,
        duration,
        presentation,
        debrief,
        players,
        type,
        theme,
        infos,
        categoryId,
    }: CreateImproOptions) {
        this.showId = showId;
        this.duration = duration;
        this.presentation = presentation;
        this.debrief = debrief;
        this.players = players;
        this.type = type;
        this.theme = theme;
        this.infos = infos;
        this.categoryId = categoryId;

        Object.freeze(this);
    }
}

export default CreateImpro;
