export type UpdateSchedulePositionPayload = {
    id: number;
    name: string;
    date: string | null;
    impros: Array<{
        id: number;
        duration: number;
        presentation: number;
        debrief: number;
        players: number | null;
        theme: string | null;
        infos: string | null;
        category: {
            id: number;
            name: string;
            userId: number | null;
        };
        type: string;
    }>;
    talks: Array<{
        id: number;
        duration: number;
        label: string;
    }>;
    pauses: Array<{
        id: number;
        duration: number;
        label: string;
    }>;
    schedules: Array<{
        id: number;
        position: number;
        itemType: string;
        itemId: number;
    }>;
};

type ComputedUpdateSchedulePositionPayload = Omit<
    UpdateSchedulePositionPayload,
    'date'
> & {
    date: Date | null;
};

const computePayload = (
    payload: UpdateSchedulePositionPayload,
): ComputedUpdateSchedulePositionPayload => {
    return {
        ...payload,
        date: payload.date ? new Date(payload.date) : null,
    };
};

export type UpdateSchedulePositionSuccessAction = {
    type: 'UPDATE_SCHEDULE_POSITION_SUCCESS';
    payload: ComputedUpdateSchedulePositionPayload;
};

const updateSchedulePositionSuccess = (
    payload: UpdateSchedulePositionPayload,
): UpdateSchedulePositionSuccessAction => {
    return {
        type: 'UPDATE_SCHEDULE_POSITION_SUCCESS',
        payload: computePayload(payload),
    };
};

export default updateSchedulePositionSuccess;
