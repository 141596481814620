import * as React from 'react';
import Context from './Context';

const useNotifications = () => {
    const value = React.useContext(Context);

    if (value === undefined) {
        throw new Error('Did you frget the <NotificationsProvider />?');
    }

    return value;
};

export default useNotifications;
