import * as React from 'react';
import Context from '.';

const useStore = <S>(key: string, initialState: S): S => {
    const context = React.useContext(Context);

    if (!context) {
        throw new Error('useStore must be used within a Store');
    }

    const state = context.state[key];

    if (!state) {
        return initialState;
    }

    return state;
};

export default useStore;
