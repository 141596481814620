export type CreateSchedulePayload = {
    id: number;
    name: string;
    date: string | null;
    impros: Array<{
        id: number;
        duration: number;
        presentation: number;
        debrief: number;
        players: number | null;
        theme: string | null;
        infos: string | null;
        category: {
            id: number;
            name: string;
            userId: number | null;
        };
        type: string;
    }>;
    talks: Array<{
        id: number;
        duration: number;
        label: string;
    }>;
    pauses: Array<{
        id: number;
        duration: number;
        label: string;
    }>;
    schedules: Array<{
        id: number;
        position: number;
        itemType: string;
        itemId: number;
    }>;
};

type ComputedCreateSchedulePayload = Omit<CreateSchedulePayload, 'date'> & {
    date: Date | null;
};

const computePayload = (
    payload: CreateSchedulePayload,
): ComputedCreateSchedulePayload => {
    return {
        ...payload,
        date: payload.date ? new Date(payload.date) : null,
    };
};

export type CreateScheduleSuccessAction = {
    type: 'CREATE_SCHEDULE_SUCCESS';
    payload: ComputedCreateSchedulePayload;
};

const createScheduleSuccess = (
    payload: CreateSchedulePayload,
): CreateScheduleSuccessAction => {
    return {
        type: 'CREATE_SCHEDULE_SUCCESS',
        payload: computePayload(payload),
    };
};

export default createScheduleSuccess;
