import * as Api from '../Domain/Api';
import DeleteTalk from './Commands/DeleteTalk';
import deleteTalkSuccess, {
    type DeleteTalkPayload,
} from './Actions/deleteTalkSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const deleteTalk = async (command: DeleteTalk) => {
    const payload = await Api.del<DeleteTalkPayload>(
        `${parameters.api.host}/talks/${command.id}`,
        {
            jwt: JWTStorer.get(),
        },
    );

    return deleteTalkSuccess(payload);
};

export default deleteTalk;
