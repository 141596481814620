import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import debug from '@Guidelines/debug';

const debugMui = debug('MUI');

declare module '@mui/joy/styles' {
    interface PaletteCommon {
        inverted: string;
    }
}

const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    '50': '#f0f9ff',
                    '100': '#e0f2fe',
                    '200': '#bae6fd',
                    '300': '#7dd3fc',
                    '400': '#38bdf8',
                    '500': '#0ea5e9',
                    '600': '#0284c7',
                    '700': '#0369a1',
                    '800': '#075985',
                    '900': '#0c4a6e',
                },
                background: {
                    body: 'var(--joy-palette-common-black, #000000)',
                },
                common: {
                    inverted: 'var(--joy-palette-common-inverted, #FEFEED)',
                },
            },
        },
    },
    fontFamily: {
        display:
            '"Limelight", var(--joy-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol")',
    },
    components: {
        JoyChip: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: theme.vars.radius.xs,
                }),
            },
        },
        JoyAccordionDetails: {
            styleOverrides: {
                root: ({ theme }) => ({
                    padding: theme.spacing(0),
                }),
            },
        },
        JoyMenuItem: {
            styleOverrides: {
                root: ({ theme }) => ({
                    whiteSpace: 'nowrap',
                }),
            },
        },
    },
});

type Props = {
    global?: boolean;
    children: React.ReactNode;
};

const Providers: React.ComponentType<Props> = ({ global, children }) => {
    debugMui({ theme });

    return (
        <DndProvider backend={HTML5Backend}>
            <IntlProvider locale="fr" messages={{}}>
                <CssVarsProvider
                    theme={theme}
                    defaultMode="light"
                    disableStyleSheetGeneration={global !== true}
                >
                    {children}
                </CssVarsProvider>
            </IntlProvider>
        </DndProvider>
    );
};

export default Providers;
