import * as React from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    Card,
    CardContent,
    CardOverflow,
    Typography,
    Divider,
    CardActions,
    Button,
} from '@mui/joy';
import makeSx from '@Guidelines/sx';
import Image, { useCloudinary } from '@Guidelines/Image';
import Hero from '@Guidelines/Hero';

const sx = makeSx({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: (theme) => ({
        boxShadow: theme.shadow.md,
    }),
    header: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerImageContainer: {
        height: '150px',
        width: '150px',
    },
});

type Props = {
    title: React.ReactNode;
    submit: React.ReactNode;
    onChange: (e: React.FormEvent<HTMLFormElement>) => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    isValid: boolean;
    isInProgress: boolean;
    isInError: boolean;
    children: React.ReactNode;
};

const Form: React.ComponentType<Props> = ({
    title,
    submit,
    onChange,
    onSubmit,
    isValid,
    isInProgress,
    isInError,
    children,
}) => {
    const cloudinary = useCloudinary();

    return (
        <Hero
            backgroundImage={
                <Image
                    src={cloudinary.image('theater-min.jpg', {
                        transformation: 'Hero',
                    })}
                    alt="Theatre curtain"
                    cover
                />
            }
        >
            <Box sx={sx.root}>
                <form onSubmit={onSubmit} onChange={onChange}>
                    <Card
                        sx={sx.card}
                        variant="plain"
                        color={isInError ? 'danger' : undefined}
                    >
                        <CardContent sx={sx.header}>
                            <Link to="/">
                                <Box sx={sx.headerImageContainer}>
                                    <Image
                                        src={cloudinary.image(
                                            '/logo/notext-transparent-min.png',
                                        )}
                                        alt="Imrokit logo"
                                    />
                                </Box>
                            </Link>
                            <Typography level="h1">{title}</Typography>
                        </CardContent>
                        <Divider />
                        <CardContent>{children}</CardContent>
                        <CardOverflow variant="soft">
                            <CardActions buttonFlex="1">
                                <Button
                                    type="submit"
                                    size="lg"
                                    loading={isInProgress}
                                    disabled={!isValid}
                                >
                                    {submit}
                                </Button>
                            </CardActions>
                        </CardOverflow>
                    </Card>
                </form>
            </Box>
        </Hero>
    );
};

export default Form;
