export type TalkParameters = {
    id: number;
    duration: number;
    label: string;
};

class Talk {
    id: number;
    duration: number;
    label: string;

    constructor({ id, duration, label }: TalkParameters) {
        this.id = id;
        this.duration = duration;
        this.label = label;

        Object.freeze(this);
    }

    getScheduleType(): string {
        return 'TALK';
    }

    computeDuration(): number {
        return this.duration;
    }
}

export default Talk;
