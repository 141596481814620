import * as React from 'react';
import { stores } from './register';
import debug from '@Guidelines/debug';

const debugUseStore = debug('useStore');

type Action = any;

type State = Record<string, any>;

const initialState: State = {};

const store: React.Reducer<State, Action> = (state, action): State => {
    debugUseStore(action);

    return Array.from(stores.entries()).reduce((state, [key, store]) => {
        const prevStoreState = state[key] || store.initialState;
        const nextStoreState = store.reducer(prevStoreState, action);

        if (prevStoreState === nextStoreState) return state;

        return {
            ...state,
            [key]: nextStoreState,
        };
    }, state);
};

const useStore = (): [State, <A>(action: A) => A] => {
    const [state, dispatch] = React.useReducer(store, initialState);

    const internalDispatch = React.useMemo(() => {
        return function <A>(action: A): A {
            dispatch(action);

            return action;
        };
    }, [dispatch]);

    return [state, internalDispatch];
};

export default useStore;
