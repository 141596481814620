import * as Api from '../Domain/Api';
import CreateImpro from './Commands/CreateImpro';
import createImproSuccess, {
    type CreateImproPayload,
} from './Actions/createImproSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const createImpro = async (command: CreateImpro) => {
    const payload = await Api.post<CreateImproPayload>(
        `${parameters.api.host}/impros`,
        {
            showId: command.showId,
            duration: command.duration,
            presentation: command.presentation,
            debrief: command.debrief,
            players: command.players,
            type: command.type,
            theme: command.theme,
            infos: command.infos,
            categoryId: command.categoryId,
        },
        {
            jwt: JWTStorer.get(),
        },
    );

    return createImproSuccess(payload);
};

export default createImpro;
