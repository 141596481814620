import * as React from 'react';
import createImpro from '@Application/createImpro';
import CreateImpro from '@Application/Commands/CreateImpro';
import createTalk from '@Application/createTalk';
import CreateTalk from '@Application/Commands/CreateTalk';
import createPause from '@Application/createPause';
import CreatePause from '@Application/Commands/CreatePause';
import createSchedule from '@Application/createSchedule';
import CreateSchedule from '@Application/Commands/CreateSchedule';
import { useDispatch } from '@Stores/index';
import {
    type ImproData as ImproFormData,
    type TalkData as TalkFormData,
    type PauseData as PauseFormData,
} from './StepModal/StepForm';
import { useNotifications } from '@Guidelines/Notification';
import StepModal from './StepModal';
import Show from '@Stores/Views/Show';

type Props = {
    show: Show;
    isOpen: boolean;
    onClose: () => void;
};

const AddStepModal: React.ComponentType<Props> = ({
    show,
    isOpen,
    onClose,
}) => {
    const dispatch = useDispatch();
    const notify = useNotifications();

    const { id: showId } = show;

    const recordSchedule = React.useCallback(
        (scheduleItemType: string, scheduleItemId: number) => {
            const thenMessages: Record<string, string> = {
                IMPRO: 'Le temps de jeu a été ajouté à votre spectacle',
                TALK: 'Le temps de parole a été ajouté à votre spectacle',
                PAUSE: 'Le temps de pause a été ajouté à votre spectacle',
                DEFAULT: 'La nouvelle étape a été ajoutée à votre spectacle',
            };

            const catchMessages: Record<string, string> = {
                IMPRO: 'Le temps de jeu a été ajouté aux étapes en réserve',
                TALK: 'Le temps de parole a été ajouté aux étapes en réserve',
                PAUSE: 'Le temps de pause a été ajouté aux étapes en réserve',
                DEFAULT:
                    'La nouvelle étape a été ajoutée aux étapes en réserve',
            };

            createSchedule(
                new CreateSchedule({
                    showId: showId,
                    itemId: scheduleItemId,
                    itemType: scheduleItemType,
                    position: null,
                }),
            )
                .then(dispatch)
                .then((action) => {
                    notify.success(
                        thenMessages[scheduleItemType] || thenMessages.DEFAULT,
                    );
                })
                .catch((e) => {
                    notify.warning(
                        catchMessages[scheduleItemType] ||
                            catchMessages.DEFAULT,
                    );
                });
        },
        [dispatch, notify, showId],
    );

    const onImproSubmit = React.useCallback(
        (data: ImproFormData) => {
            createImpro(
                new CreateImpro({
                    showId: showId,
                    duration: parseInt(data.duration),
                    presentation: parseInt(data.presentation),
                    debrief: parseInt(data.debrief),
                    players:
                        data.players.length > 0 ? parseInt(data.players) : null,
                    type: data.type,
                    theme: data.theme || null,
                    infos: data.infos || null,
                    categoryId: data.categoryId,
                }),
            )
                .then(dispatch)
                .then((action) => {
                    recordSchedule('IMPRO', action.payload.id);
                })
                .then(onClose);
        },
        [dispatch, recordSchedule, showId, onClose],
    );

    const onTalkSubmit = React.useCallback(
        (data: TalkFormData) => {
            createTalk(
                new CreateTalk({
                    showId: showId,
                    duration: parseInt(data.duration),
                    label: data.label,
                }),
            )
                .then(dispatch)
                .then((action) => {
                    recordSchedule('TALK', action.payload.id);
                })
                .then(onClose);
        },
        [dispatch, recordSchedule, showId, onClose],
    );

    const onPauseSubmit = React.useCallback(
        (data: PauseFormData) => {
            createPause(
                new CreatePause({
                    showId: showId,
                    duration: parseInt(data.duration),
                    label: data.label,
                }),
            )
                .then(dispatch)
                .then((action) => {
                    recordSchedule('PAUSE', action.payload.id);
                })
                .then(onClose);
        },
        [dispatch, recordSchedule, showId, onClose],
    );

    return (
        <StepModal
            title="Ajouter une nouvelle étape"
            submitLabel="Ajouter"
            cancelLabel="Annuler"
            isOpen={isOpen}
            onClose={onClose}
            onImproSubmit={onImproSubmit}
            onTalkSubmit={onTalkSubmit}
            onPauseSubmit={onPauseSubmit}
        />
    );
};

export default AddStepModal;
