export type SignupUserPayload = {
    id: number;
    email: string;
};

export type SignupUserSuccessAction = {
    type: 'SIGNUP_USER_SUCCESS';
    payload: SignupUserPayload;
};

const signupUserSuccess = (
    payload: SignupUserPayload,
): SignupUserSuccessAction => {
    return {
        type: 'SIGNUP_USER_SUCCESS',
        payload: payload,
    };
};

export default signupUserSuccess;
