import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Stack,
    FormControl,
    FormLabel,
    Input,
    Divider,
    Link as MuiLink,
} from '@mui/joy';
import signupUser from '@Application/signupUser';
import SignupUser from '@Application/Commands/SignupUser';
import { useNotifications } from '@Guidelines/Notification';
import PasswordStrength from '@Guidelines/Form/PasswordStrength';
import computePasswordStrength, {
    passwordStrengths,
} from '@Guidelines/Form/computePasswordStrength';
import Form from './Form';
import makeSx from '@Guidelines/sx';

const sx = makeSx({
    connectLink: {
        width: '100%',
        justifyContent: 'center',
    },
});

type Props = {};

const Signup: React.ComponentType<Props> = () => {
    const [passwordStrength, setPasswordStrength] = React.useState<number>(0);
    const [isValid, setIsValid] = React.useState<boolean>(false);
    const [isInError, setIsInError] = React.useState<boolean>(false);
    const [isInProgress, setIsInProgress] = React.useState<boolean>(false);

    const notify = useNotifications();
    const navigate = useNavigate();

    React.useEffect(() => {}, []);

    const onPasswordChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setPasswordStrength(computePasswordStrength(e.currentTarget.value));
        },
        [],
    );

    const onSubmit = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            const formData = new FormData(e.currentTarget);

            setIsInProgress(true);

            signupUser(
                new SignupUser({
                    email: formData.get('email') as string,
                    password: formData.get('password') as string,
                }),
            )
                .then(({ payload }) => {
                    notify.success(
                        <span>
                            Votre compte <b>{payload.email}</b> a été créé
                        </span>,
                    );

                    navigate('/signin');
                })
                .catch(() => {
                    setIsInProgress(false);
                    setIsInError(true);

                    notify.error(
                        'Un compte avec cette adresse e-mail existe déjà.',
                    );
                });
        },
        [notify, navigate],
    );

    const onChange = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            const formData = new FormData(e.currentTarget);

            const isValid =
                String(formData.get('email')) !== '' &&
                String(formData.get('email')).indexOf('@') !== -1 &&
                String(formData.get('password')) !== '' &&
                String(formData.get('password')) ===
                    String(formData.get('confirmation'));

            setIsInError(false);
            setIsValid(isValid);
        },
        [],
    );

    return (
        <Form
            title="Inscription"
            submit="Inscription"
            onChange={onChange}
            onSubmit={onSubmit}
            isValid={isValid && passwordStrength >= passwordStrengths.STRONG}
            isInProgress={isInProgress}
            isInError={isInError}
        >
            <Stack spacing={2}>
                <FormControl error={isInError} required>
                    <FormLabel>Adresse e-mail</FormLabel>
                    <Input
                        name="email"
                        type="email"
                        placeholder="Saisissez votre adresse e-mail"
                        autoFocus
                        autoComplete="email"
                    />
                </FormControl>
                <FormControl error={isInError} required>
                    <FormLabel>Mot de passe</FormLabel>
                    <Stack spacing={0.5}>
                        <Input
                            name="password"
                            type="password"
                            onChange={onPasswordChange}
                            placeholder="Saisissez votre mot de passe"
                            autoComplete="new-password"
                        />
                        <PasswordStrength strength={passwordStrength} />
                    </Stack>
                </FormControl>
                <FormControl error={isInError} required>
                    <FormLabel>Confirmation</FormLabel>
                    <Input
                        name="confirmation"
                        type="password"
                        placeholder="Saisissez votre mot de passe"
                        autoComplete="new-password"
                    />
                </FormControl>
                <Divider>ou</Divider>
                <Link to="/signin">
                    <MuiLink component="div" sx={sx.connectLink}>
                        Se connecter
                    </MuiLink>
                </Link>
            </Stack>
        </Form>
    );
};

export default Signup;
