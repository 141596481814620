import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Stack,
    FormControl,
    FormLabel,
    Input,
    Divider,
    Link as MuiLink,
    IconButton,
} from '@mui/joy';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import signinUser from '@Application/signinUser';
import SigninUser from '@Application/Commands/SigninUser';
import { useNotifications } from '@Guidelines/Notification';
import Form from './Form';
import makeSx from '@Guidelines/sx';
import { useDispatch } from '@Stores/index';

const sx = makeSx({
    connectLink: {
        width: '100%',
        justifyContent: 'center',
    },
});

type Props = {};

const Signin: React.ComponentType<Props> = () => {
    const [isValid, setIsValid] = React.useState<boolean>(false);
    const [isInError, setIsInError] = React.useState<boolean>(false);
    const [isInProgress, setIsInProgress] = React.useState<boolean>(false);
    const [isPasswordVisible, setIsPasswordVisible] =
        React.useState<boolean>(false);

    const navigate = useNavigate();
    const notify = useNotifications();
    const dispatch = useDispatch();

    const onSubmit = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            const formData = new FormData(e.currentTarget);

            setIsInProgress(true);

            signinUser(
                new SigninUser({
                    email: formData.get('email') as string,
                    password: formData.get('password') as string,
                }),
            )
                .then(dispatch)
                .then(() => {
                    navigate('/_');
                })
                .catch(() => {
                    setIsInProgress(false);
                    setIsInError(true);

                    notify.error('Identifiants incorrects');
                });
        },
        [navigate, notify, dispatch],
    );

    const onChange = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            const formData = new FormData(e.currentTarget);

            const isValid =
                String(formData.get('email')) !== '' &&
                String(formData.get('email')).indexOf('@') !== -1 &&
                String(formData.get('password')) !== '';

            setIsValid(isValid);
            setIsInError(false);
        },
        [],
    );

    const onPasswordVisibilityToggleClick = React.useCallback(() => {
        return setIsPasswordVisible(!isPasswordVisible);
    }, [isPasswordVisible]);

    return (
        <Form
            title="Connexion"
            submit="Connexion"
            onChange={onChange}
            onSubmit={onSubmit}
            isValid={isValid}
            isInProgress={isInProgress}
            isInError={isInError}
        >
            <Stack spacing={2}>
                <FormControl error={isInError} required>
                    <FormLabel>Adresse e-mail</FormLabel>
                    <Input
                        name="email"
                        type="email"
                        placeholder="Saisissez votre adresse e-mail"
                        autoFocus
                        autoComplete="email"
                    />
                </FormControl>
                <FormControl error={isInError} required>
                    <FormLabel>Mot de passe</FormLabel>
                    <Input
                        name="password"
                        type={isPasswordVisible ? 'text' : 'password'}
                        placeholder="Saisissez votre mot de passe"
                        autoComplete="current-password"
                        endDecorator={
                            <IconButton
                                onClick={onPasswordVisibilityToggleClick}
                            >
                                {isPasswordVisible ? (
                                    <VisibilityOffIcon />
                                ) : (
                                    <VisibilityIcon />
                                )}
                            </IconButton>
                        }
                    />
                </FormControl>
                <Divider>ou</Divider>
                <Link to="/signup">
                    <MuiLink component="div" sx={sx.connectLink}>
                        Créer un compte
                    </MuiLink>
                </Link>
            </Stack>
        </Form>
    );
};

export default Signin;
