export type CreateImproPayload = {
    id: number;
    showId: number;
    duration: number;
    presentation: number;
    debrief: number;
    players: number | null;
    theme: string | null;
    infos: string | null;
    category: {
        id: number;
        name: string;
        userId: number | null;
    };
    type: string;
};

export type CreateImproSuccessAction = {
    type: 'CREATE_IMPRO_SUCCESS';
    payload: CreateImproPayload;
};

const createImproSuccess = (
    payload: CreateImproPayload,
): CreateImproSuccessAction => {
    return {
        type: 'CREATE_IMPRO_SUCCESS',
        payload: payload,
    };
};

export default createImproSuccess;
