import * as React from 'react';
import { useTheme, Box, BoxProps } from '@mui/joy';
import { type Breakpoint } from '@mui/system';

type Props = {
    breakpoints?: {
        [key in Breakpoint]?: boolean;
    };
} & BoxProps;

const Hidden: React.ComponentType<Props> = ({
    breakpoints,
    children,
    ...box
}) => {
    const theme = useTheme();

    const sx = React.useMemo(() => {
        const breakpointsEntries = Object.entries({
            xs: true,
            ...(breakpoints || {}),
        }) as Array<[Breakpoint, boolean]>;

        return breakpointsEntries.reduce((acc, [breakpoint, isHidden]) => {
            return {
                ...acc,
                [theme.breakpoints.up(breakpoint)]: isHidden
                    ? { display: 'none' }
                    : { display: 'initial' },
            };
        }, box.sx || {});
    }, [breakpoints, theme, box.sx]);

    return (
        <Box {...box} sx={sx}>
            {children}
        </Box>
    );
};

export default Hidden;
