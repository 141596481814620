import * as React from 'react';
import makeSx from '@Guidelines/sx';
import { Box } from '@mui/joy';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { keyframes } from '@mui/system';

const more = keyframes`
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(100%);
        opacity: 1;
    }
`;

const sx = makeSx({
    root: {
        position: 'relative',
        minHeight: '100dvh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'black',
        overflow: 'hidden',
    },
    content: (theme) => ({
        display: 'flex',
        position: 'relative',
        flex: '1 0 auto',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }),
    background: {
        position: 'absolute',
        height: '100%',
        width: '100%',
    },
    more: (theme) => ({
        position: 'absolute',
        bottom: 0,
        width: '100%',
        padding: theme.spacing(2),
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '4rem',
    }),
    moreIcon: {
        fontSize: 'inherit',
        animation: `${more} 4s infinite`,
    },
});

type Props = {
    backgroundImage?: React.ReactNode;
    more?: boolean;
    children?: React.ReactNode;
};

const Hero: React.ComponentType<Props> = ({
    backgroundImage,
    more,
    children,
}) => {
    return (
        <Box sx={sx.root}>
            {backgroundImage && <Box sx={sx.background}>{backgroundImage}</Box>}
            <Box sx={sx.content}>{children}</Box>
            {more && (
                <Box sx={sx.more}>
                    <ExpandMoreIcon
                        color="inherit"
                        fontSize="inherit"
                        sx={sx.moreIcon}
                    />
                </Box>
            )}
        </Box>
    );
};

export default Hero;
