export type CategoryParameters = {
    id: number;
    name: string;
    userId: number | null;
};

class Category {
    id: number;
    name: string;
    userId: number | null;

    constructor({ id, name, userId }: CategoryParameters) {
        this.id = id;
        this.name = name;
        this.userId = userId;

        Object.freeze(this);
    }
}

export default Category;
