import Schedule from './Schedule';

class Schedules {
    schedules: Array<Schedule>;

    constructor(schedules: Array<Schedule>) {
        this.schedules = schedules;

        Object.freeze(this);
    }

    orderByPosition(asc?: boolean): Schedules {
        const multiplicator = asc === false ? -1 : 1;

        const orderedSchedules = this.schedules.sort((a, b) => {
            return (a.position - b.position) * multiplicator;
        });

        return new Schedules(orderedSchedules);
    }

    getFirstPosition(): number {
        return (
            Math.min(...this.schedules.map((schedule) => schedule.position)) ||
            0
        );
    }

    getLastPosition(): number {
        return (
            Math.max(...this.schedules.map((schedule) => schedule.position)) ||
            0
        );
    }

    findByItem(itemType: string, itemId: number): Schedules {
        return new Schedules(
            this.schedules.filter(
                (schedule) =>
                    schedule.itemType === itemType &&
                    schedule.itemId === itemId,
            ),
        );
    }

    filterByType(itemType: string): Schedules {
        return new Schedules(
            this.schedules.filter((schedule) => schedule.itemType === itemType),
        );
    }

    hasItem(itemType: string, itemId: number): boolean {
        return (
            this.schedules.find((schedule) => {
                return (
                    schedule.itemType === itemType && schedule.itemId === itemId
                );
            }) !== undefined
        );
    }
}

export default Schedules;
