export type UpdateImproPayload = {
    id: number;
    showId: number;
    duration: number;
    presentation: number;
    debrief: number;
    players: number | null;
    theme: string | null;
    infos: string | null;
    category: {
        id: number;
        name: string;
        userId: number | null;
    };
    type: string;
};

export type UpdateImproSuccessAction = {
    type: 'UPDATE_IMPRO_SUCCESS';
    payload: UpdateImproPayload;
};

const updateImproSuccess = (
    payload: UpdateImproPayload,
): UpdateImproSuccessAction => {
    return {
        type: 'UPDATE_IMPRO_SUCCESS',
        payload: payload,
    };
};

export default updateImproSuccess;
