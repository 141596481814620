export type DeleteImproPayload = {
    id: number;
    showId: number;
};

export type DeleteImproSuccessAction = {
    type: 'DELETE_IMPRO_SUCCESS';
    payload: DeleteImproPayload;
};

const deleteImproSuccess = (
    payload: DeleteImproPayload,
): DeleteImproSuccessAction => {
    return {
        type: 'DELETE_IMPRO_SUCCESS',
        payload: payload,
    };
};

export default deleteImproSuccess;
