import * as Api from '../Domain/Api';
import FetchShow from './Queries/FetchShow';
import fetchShowSuccess, {
    type FetchShowPayload,
    type FetchShowSuccessAction,
} from './Actions/fetchShowSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const fetchShow = async (query: FetchShow): Promise<FetchShowSuccessAction> => {
    const payload = await Api.get<FetchShowPayload>(
        `${parameters.api.host}/shows/${query.id}`,
        {
            jwt: JWTStorer.get(),
        },
    );

    return fetchShowSuccess(payload);
};

export default fetchShow;
