import * as React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Layout from './Layout';
import Home from './Home';
import { Signin, Signup } from './Login';

type Props = {};

const Public: React.ComponentType<Props> = () => {
    return (
        <Routes>
            <Route
                element={
                    <Layout>
                        <Outlet />
                    </Layout>
                }
            >
                <Route path="/" element={<Home />} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/signup" element={<Signup />} />
            </Route>
        </Routes>
    );
};

export default Public;
