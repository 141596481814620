export type FetchUserPayload = {
    id: number;
    email: string;
};

export type FetchUserSuccessAction = {
    type: 'FETCH_USER_SUCCESS';
    payload: FetchUserPayload;
};

const fetchUserSuccess = (
    payload: FetchUserPayload,
): FetchUserSuccessAction => {
    return {
        type: 'FETCH_USER_SUCCESS',
        payload: payload,
    };
};

export default fetchUserSuccess;
