import Show from './Show';

class Shows {
    shows: Array<Show>;

    constructor(shows: Array<Show>) {
        this.shows = shows;

        Object.freeze(this);
    }

    filterPastShows(): Shows {
        return new Shows(
            this.shows.filter((show) => show.date && show.date < new Date()),
        );
    }

    filterWaitingShows(): Shows {
        return new Shows(this.shows.filter((show) => !show.date));
    }

    filterFutureShows(): Shows {
        return new Shows(
            this.shows.filter((show) => show.date && show.date >= new Date()),
        );
    }

    orderById(asc?: boolean): Shows {
        const multiplicator = asc === false ? -1 : 1;

        const orderedShows = this.shows.sort(
            (a, b) => (a.id - b.id) * multiplicator,
        );

        return new Shows(orderedShows);
    }

    orderByDate(asc?: boolean): Shows {
        const multiplicator = asc === false ? -1 : 1;

        const orderedShows = this.shows.sort((a, b) => {
            if (!a.date) return 1 * multiplicator;
            if (!b.date) return -1 * multiplicator;

            return (a.date.getTime() - b.date.getTime()) * multiplicator;
        });

        return new Shows(orderedShows);
    }

    findBydId(id: number): Show | undefined {
        return this.shows.find((show) => show.id === id);
    }

    addShow(show: Show): Shows {
        return new Shows(this.shows.concat([show]));
    }

    updateShow(show: Show): Shows {
        return new Shows(
            this.shows.map((existingShow) =>
                existingShow.id === show.id ? show : existingShow,
            ),
        );
    }
}

export default Shows;
