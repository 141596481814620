import { Cloudinary } from '@cloudinary/url-gen';
import { name } from '@cloudinary/url-gen/actions/namedTransformation';

const cloudinary = new Cloudinary({
    cloud: {
        cloudName: 'djgborpvu',
    },
});

type ImageOptions = {
    transformation?: string;
};

const useCloudinary = () => ({
    image: (path: string, options: ImageOptions | void): string => {
        const { transformation } = options || {
            transformation: 'Default',
        };

        const image = cloudinary.image(`ImproKit/${path}`.replace(/\/+/g, '/'));

        const transformedImage = transformation
            ? image.namedTransformation(name(transformation))
            : image;

        return transformedImage.toURL();
    },
});

export default useCloudinary;
