import * as React from 'react';
import { ShowStep } from '@Stores/Views/Show';
import { Avatar, Card, CardContent, Chip, Typography } from '@mui/joy';
import { Timelapse as TimelapseIcon } from '@mui/icons-material';
import makeSx from '@Guidelines/sx';
import { useIntl } from 'react-intl';
import Hidden from '@Guidelines/Hidden';

const sx = makeSx({
    root: {
        flex: '1 1 auto',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    label: {
        // whiteSpace: 'nowrap',
    },
    content: {
        justifyContent: 'space-between',
    },
});

type Props = {
    steps: Array<ShowStep>;
    label: React.ReactNode;
    icon: React.ReactNode;
    children?: React.ReactNode;
};

const ScheduleStepKey: React.ComponentType<Props> = ({
    steps,
    label,
    icon,
}) => {
    const intl = useIntl();

    const count = steps.length;
    const duration = steps.reduce((acc, step) => {
        return acc + step.computeDuration();
    }, 0);

    return (
        <Card
            variant="solid"
            invertedColors
            sx={sx.root}
            orientation="horizontal"
        >
            <Typography
                level="h4"
                startDecorator={<Avatar>{icon}</Avatar>}
                sx={sx.label}
            >
                <Hidden breakpoints={{ sm: false }}>{label}</Hidden>
            </Typography>
            <CardContent orientation="horizontal" sx={sx.content}>
                <Chip
                    variant="plain"
                    size="lg"
                    startDecorator={<TimelapseIcon />}
                >
                    {intl.formatNumber(duration, {
                        style: 'unit',
                        unit: 'minute',
                        unitDisplay: 'long',
                    })}
                </Chip>
                <Typography level="h3">{count}</Typography>
            </CardContent>
        </Card>
    );
};

export default ScheduleStepKey;
