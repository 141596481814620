import * as React from 'react';
import { useDrop } from 'react-dnd';
import makeSx, { combineSX } from '@Guidelines/sx';
import { Box } from '@mui/joy';

const sx = makeSx({
    root: (theme) => ({
        height: theme.spacing(7),
        margin: `${theme.spacing(-3)} ${theme.spacing(-1)} ${theme.spacing(-4)} ${theme.spacing(-1)} !important`,
        zIndex: 0,
        position: 'relative',
        transform: 'scale(1, 0)',
    }),
    dropActive: (theme) => ({
        transform: 'scale(1, 1)',
        zIndex: 3,
        transition: 'all 0.25s',
    }),
    dropOver: (theme) => ({
        height: theme.spacing(10),
    }),
    content: (theme) => ({
        position: 'absolute',
        height: theme.spacing(0.5),
        width: '100%',
        top: '50%',
        transform: 'translateY(-50%)',
    }),
    contentOver: (theme) => ({
        background: theme.palette.primary.solidHoverBg,
        '&::before, &::after': {
            position: 'absolute',
            top: theme.spacing(0.25),
            content: '""',
            background: 'transparent',
            height: 0,
            width: 0,
            transform: 'translateY(-50%)',
            borderTop: `${theme.spacing(2)} solid transparent`,
            borderBottom: `${theme.spacing(2)} solid transparent`,
        },
        '&:before': {
            left: theme.spacing(-1.5),
            borderLeft: `${theme.spacing(1)} solid ${theme.palette.primary.solidHoverBg}`,
        },
        '&:after': {
            right: theme.spacing(-1.5),
            borderRight: `${theme.spacing(1)} solid ${theme.palette.primary.solidHoverBg}`,
        },
    }),
});

type Props = {
    position: number | null;
};

const ScheduleItemDndDestination: React.ComponentType<Props> = ({
    position,
}) => {
    const [collected, dropRef] = useDrop({
        accept: 'scheduleItem',
        drop: () => ({ position }),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    });

    return (
        <Box
            style={{}}
            sx={combineSX(
                sx.root,
                collected.canDrop ? sx.dropActive : null,
                collected.isOver ? sx.dropOver : null,
            )}
            ref={dropRef}
        >
            <Box
                sx={combineSX(
                    sx.content,
                    collected.isOver ? sx.contentOver : null,
                )}
            ></Box>
        </Box>
    );
};

export default ScheduleItemDndDestination;
