import * as Api from '../Domain/Api';
import UpdateShowDetails from './Commands/UpdateShowDetails';
import updateShowDetailsSuccess, {
    type UpdateShowDetailsPayload,
} from './Actions/updateShowDetailsSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const updateShowDetails = async (command: UpdateShowDetails) => {
    const payload = await Api.patch<UpdateShowDetailsPayload>(
        `${parameters.api.host}/shows/${command.id}`,
        {
            name: command.name,
            date: command.date,
        },
        {
            jwt: JWTStorer.get(),
        },
    );

    return updateShowDetailsSuccess(payload);
};

export default updateShowDetails;
