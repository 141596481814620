import * as React from 'react';
import { useIntl } from 'react-intl';
import {
    Card,
    CardContent,
    Typography,
    type CardProps,
    CardOverflow,
    Box,
    Badge,
} from '@mui/joy';
import { Info as InfoIcon, Timer as TimerIcon } from '@mui/icons-material';
import makeSx from '@Guidelines/sx';

const sx = makeSx({
    root: () => ({}),
    content: (theme) => ({
        alignItems: 'flex-start',
        marginLeft: `calc(var(--Card-padding) * -1 + ${theme.spacing(2)})`,
        marginRight: `calc(var(--Card-padding) * -1 + ${theme.spacing(2)})`,
    }),
    children: () => ({
        flex: '1 1 0',
        display: 'flex',
    }),
    duration: () => ({
        width: '4.9em',
        justifyContent: 'space-between',
    }),
    infos: {
        width: '100%',
        whiteSpace: 'pre-wrap',
        alignItems: 'normal',
    },
});

export type Props = {
    duration: number | { decorator: string; value: number };
    infos?: React.ReactNode;
} & Omit<CardProps, 'orientation'>;

const ScheduleItemLine: React.ComponentType<Props> = ({
    duration,
    infos,
    children,
    ...card
}) => {
    const intl = useIntl();

    const durationValue =
        typeof duration === 'number' ? duration : duration.value;
    const durationDecorator =
        typeof duration === 'number' ? null : duration.decorator;

    return (
        <Card {...card} sx={sx.root}>
            <CardContent orientation="horizontal" sx={sx.content}>
                <Box sx={sx.children}>{children}</Box>
                <Badge
                    badgeContent={durationDecorator}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeInset={'-50% 10%'}
                    invisible={!durationDecorator}
                    color="warning"
                    variant="plain"
                >
                    <Typography
                        sx={sx.duration}
                        startDecorator={<TimerIcon size="sm" />}
                        textColor="inherit"
                    >
                        {intl.formatNumber(durationValue, {
                            style: 'unit',
                            unit: 'minute',
                            unitDisplay: 'short',
                        })}
                    </Typography>
                </Badge>
            </CardContent>
            {infos && (
                <CardOverflow variant="soft">
                    <CardContent sx={sx.content}>
                        <Typography
                            level="body-sm"
                            sx={sx.infos}
                            startDecorator={<InfoIcon />}
                        >
                            {infos}
                        </Typography>
                    </CardContent>
                </CardOverflow>
            )}
        </Card>
    );
};

export default ScheduleItemLine;
