import * as React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Layout from './Layout';
import Private from './Private';
import Public from './Public';

type Props = {};

const Pages: React.ComponentType<Props> = () => {
    return (
        <Routes>
            <Route
                element={
                    <Layout>
                        <Outlet />
                    </Layout>
                }
            >
                <Route path="/*" element={<Public />} />
                <Route path="/_/*" element={<Private />} />
            </Route>
        </Routes>
    );
};

export default Pages;
