import * as React from 'react';

type Props = {
    children: React.ReactNode;
};

const Page: React.ComponentType<Props> = ({ children }) => {
    return <React.Fragment>{children}</React.Fragment>;
};

export default Page;
