import * as React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button, Stack } from '@mui/joy';
import Hero from '@Guidelines/Hero';
import Image, { useCloudinary } from '@Guidelines/Image';
import makeSx from '@Guidelines/sx';

const sx = makeSx({
    contentContainer: (theme) => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(12),
    }),
    centerContainer: {
        flex: 1,
        width: '100%',
    },
    imageContainer: (theme) => ({
        width: '100%',
        height: '100%',
        maxWidth: '60vw',
        maxHeight: '60vw',
        [theme.breakpoints.up('md')]: {
            maxWidth: '400px',
            maxHeight: '400px',
        },
    }),
    title: (theme) => ({
        textAlign: 'center',
        color: theme.palette.common.inverted,
    }),
    h1: (theme) => ({
        [theme.breakpoints.up('md')]: {
            fontSize: '3.4rem',
        },
    }),
    h2: (theme) => ({
        [theme.breakpoints.up('md')]: {
            fontSize: '2.25rem',
        },
    }),
    CTAContainer: (theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '6vh',
    }),
});

type Props = {};

const HomeHero: React.ComponentType<Props> = () => {
    const cloudinary = useCloudinary();

    return (
        <Hero
            backgroundImage={
                <Image
                    src={cloudinary.image('theater-min.jpg', {
                        transformation: 'Hero',
                    })}
                    alt="Theatre curtain"
                    cover
                />
            }
            more
        >
            <Box sx={sx.contentContainer}>
                <Stack
                    sx={sx.centerContainer}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box sx={sx.imageContainer}>
                        <Image
                            src={cloudinary.image(
                                'logo/notext-transparent-min.png',
                            )}
                            alt="Improkit logo"
                        />
                    </Box>
                    <Box sx={sx.title}>
                        <Typography
                            level="h1"
                            sx={sx.h1}
                            textColor="inherit"
                            gutterBottom
                        >
                            IMPROKIT
                        </Typography>
                        <Typography level="h2" sx={sx.h2} textColor="inherit">
                            La boite à outils de l'improvisateur
                        </Typography>
                    </Box>
                    <Box sx={sx.CTAContainer}>
                        <Link to={'_'}>
                            <Button size="lg" type="button" variant="solid">
                                Créez votre soirée d'impro
                            </Button>
                        </Link>
                    </Box>
                </Stack>
            </Box>
        </Hero>
    );
};

export default HomeHero;
