import * as React from 'react';
import makeSx from '@Guidelines/sx';
import { Box } from '@mui/joy';

const sx = makeSx({
    root: (theme) => ({
        position: 'relative',
        zIndex: 1,
        padding: theme.spacing(2),
        flex: '1 1 auto',
        overflow: 'visible',
        [theme.breakpoints.up('md')]: {
            overflow: 'auto',
            padding: theme.spacing(3),
        },
    }),
});

type Props = {
    children: React.ReactNode;
};

const PageContent: React.ComponentType<Props> = ({ children }) => {
    return <Box sx={sx.root}>{children}</Box>;
};

export default PageContent;
