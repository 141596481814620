import * as Api from '../Domain/Api';
import DeleteImpro from './Commands/DeleteImpro';
import deleteImproSuccess, {
    type DeleteImproPayload,
} from './Actions/deleteImproSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const deleteImpro = async (command: DeleteImpro) => {
    const payload = await Api.del<DeleteImproPayload>(
        `${parameters.api.host}/impros/${command.id}`,
        {
            jwt: JWTStorer.get(),
        },
    );

    return deleteImproSuccess(payload);
};

export default deleteImpro;
