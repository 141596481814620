import * as React from 'react';
import {
    FormControl,
    FormLabel,
    Typography,
    RadioGroup,
    Radio,
    Card,
    Stack,
    Box,
    Avatar,
} from '@mui/joy';
import {
    MergeType as MergeTypeIcon,
    Pause as PauseIcon,
    SportsKabaddi as SportsKabaddiIcon,
    RecordVoiceOver as RecordVoiceOverIcon,
} from '@mui/icons-material';
import Modal from '@Guidelines/Modal';
import makeSX, { combineSX } from '@Guidelines/sx';
import {
    Impro as ImproForm,
    type ImproData as ImproFormData,
    Talk as TalkForm,
    type TalkData as TalkFormData,
    Pause as PauseForm,
    type PauseData as PauseFormData,
} from './StepForm';

const sx = makeSX({
    typeList: (theme) => ({
        flexDirection: 'row',
        gap: theme.spacing(2),
        margin: 0,
    }),
    typeListItem: (theme) => ({
        flex: '1 1 0%',
    }),
    typeListItemDisabled: {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
    typeListItemRadio: (theme) => ({
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    }),
});

type Props = {
    type?: string;
    impro?: ImproFormData;
    talk?: TalkFormData;
    pause?: PauseFormData;
    onImproSubmit: (data: ImproFormData) => void;
    onTalkSubmit: (data: TalkFormData) => void;
    onPauseSubmit: (data: PauseFormData) => void;
} & Omit<React.ComponentProps<typeof Modal>, 'onSubmit' | 'children'>;

const StepModal: React.ComponentType<Props> = ({
    type: defaultType,
    impro: defaultImpro,
    talk: defaultTalk,
    pause: defaultPause,
    onImproSubmit,
    onTalkSubmit,
    onPauseSubmit,
    ...modalProps
}) => {
    const [type, setType] = React.useState<string>(defaultType || 'IMPRO');
    const [improData, setImproData] = React.useState<ImproFormData>(
        defaultImpro || {
            duration: '',
            presentation: '',
            debrief: '',
            players: '',
            type: '',
            theme: '',
            infos: '',
            categoryId: null,
        },
    );
    const [talkData, setTalkData] = React.useState<TalkFormData>(
        defaultTalk || {
            duration: '',
            label: '',
        },
    );
    const [pauseData, setPauseData] = React.useState<PauseFormData>(
        defaultPause || {
            duration: '',
            label: '',
        },
    );

    const isTypeDisabled = React.useMemo(
        () => typeof defaultType === 'string',
        [defaultType],
    );

    const onTypeChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (isTypeDisabled) return;

            setType(e.target.value);
        },
        [isTypeDisabled],
    );

    const onImproFormChange = React.useCallback((data: any) => {
        setImproData(data);
    }, []);

    const onTalkFormChange = React.useCallback((data: any) => {
        setTalkData(data);
    }, []);

    const onPauseFormChange = React.useCallback((data: any) => {
        setPauseData(data);
    }, []);

    const onModalSubmit = React.useCallback(() => {
        switch (type) {
            case 'IMPRO':
                return onImproSubmit(improData);
            case 'TALK':
                return onTalkSubmit(talkData);
            case 'PAUSE':
                return onPauseSubmit(pauseData);
            default:
                return null;
        }
    }, [
        onImproSubmit,
        onPauseSubmit,
        onTalkSubmit,
        improData,
        pauseData,
        talkData,
        type,
    ]);

    const renderTypeRadio = (radio: {
        type: string;
        label: string;
        icon: React.ReactNode;
    }) => {
        const isDisabled = isTypeDisabled && defaultType !== radio.type;

        return (
            <Card
                sx={combineSX(
                    sx.typeListItem,
                    isDisabled ? sx.typeListItemDisabled : null,
                )}
            >
                <Radio
                    disabled={isDisabled}
                    variant={type === radio.type ? 'soft' : 'plain'}
                    color={type === radio.type ? 'primary' : 'neutral'}
                    overlay
                    sx={sx.typeListItemRadio}
                    disableIcon
                    value={radio.type}
                    label={
                        <Stack spacing={1} alignItems="center">
                            <Avatar
                                size="lg"
                                variant={isDisabled ? 'soft' : 'solid'}
                                color={
                                    type === radio.type ? 'primary' : 'neutral'
                                }
                            >
                                {radio.icon}
                            </Avatar>
                            <Box>{radio.label}</Box>
                        </Stack>
                    }
                />
            </Card>
        );
    };

    const renderForm = () => {
        switch (type) {
            case 'IMPRO':
                return (
                    <ImproForm data={improData} onChange={onImproFormChange} />
                );
            case 'TALK':
                return <TalkForm data={talkData} onChange={onTalkFormChange} />;
            case 'PAUSE':
                return (
                    <PauseForm data={pauseData} onChange={onPauseFormChange} />
                );
            default:
                return null;
        }
    };

    return (
        <Modal {...modalProps} onSubmit={onModalSubmit}>
            <FormControl required disabled={isTypeDisabled}>
                <FormLabel>
                    <Typography
                        startDecorator={<MergeTypeIcon />}
                        textColor="inherit"
                    >
                        Étape
                    </Typography>
                </FormLabel>
                <RadioGroup
                    value={type}
                    onChange={onTypeChange}
                    sx={sx.typeList}
                >
                    {renderTypeRadio({
                        type: 'IMPRO',
                        label: 'Temps de jeu',
                        icon: <SportsKabaddiIcon />,
                    })}
                    {renderTypeRadio({
                        type: 'TALK',
                        label: 'Temps de parole',
                        icon: <RecordVoiceOverIcon />,
                    })}
                    {renderTypeRadio({
                        type: 'PAUSE',
                        label: 'Temps de pause',
                        icon: <PauseIcon />,
                    })}
                </RadioGroup>
            </FormControl>
            <Stack spacing={2}>{renderForm()}</Stack>
        </Modal>
    );
};

export default StepModal;
