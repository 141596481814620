import * as React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
    children: React.ReactNode;
};

const ErrorBoundary: React.ComponentType<Props> = ({ children }) => {
    const navigate = useNavigate();

    React.useEffect(() => {
        window.onunhandledrejection = (e: PromiseRejectionEvent) => {
            const { reason } = e;

            if (
                (reason.name === 'ApiError' && reason.status === 401) ||
                reason.name === 'JWTError'
            ) {
                e.preventDefault();
                e.stopImmediatePropagation();
                navigate('/signin');
            }
        };
    }, [navigate]);

    return <React.Fragment>{children}</React.Fragment>;
};

export default ErrorBoundary;
