import * as React from 'react';
import updateImpro from '@Application/updateImpro';
import UpdateImpro from '@Application/Commands/UpdateImpro';
import updateTalk from '@Application/updateTalk';
import UpdateTalk from '@Application/Commands/UpdateTalk';
import updatePause from '@Application/updatePause';
import UpdatePause from '@Application/Commands/UpdatePause';
import { useDispatch } from '@Stores/index';
import {
    type ImproData as ImproFormData,
    type TalkData as TalkFormData,
    type PauseData as PauseFormData,
} from './StepModal/StepForm';
import { useNotifications } from '@Guidelines/Notification';
import StepModal from './StepModal';
import { ShowStep } from '@Stores/Views/Show';
import Impro from '@Stores/Views/Impro';
import Talk from '@Stores/Views/Talk';
import Pause from '@Stores/Views/Pause';

type Props = {
    step: ShowStep;
    isOpen: boolean;
    onClose: () => void;
};

const UpdateStepModal: React.ComponentType<Props> = ({
    step,
    isOpen,
    onClose,
}) => {
    const dispatch = useDispatch();
    const notify = useNotifications();

    const type = React.useMemo(() => {
        if (step instanceof Impro) return 'IMPRO';
        if (step instanceof Talk) return 'TALK';
        if (step instanceof Pause) return 'PAUSE';

        return undefined;
    }, [step]);

    const onImproSubmit = React.useCallback(
        (data: ImproFormData) => {
            if (step instanceof Impro === false) return;

            updateImpro(
                new UpdateImpro({
                    id: step.id,
                    duration: parseInt(data.duration),
                    presentation: parseInt(data.presentation),
                    debrief: parseInt(data.debrief),
                    players:
                        data.players.length > 0 ? parseInt(data.players) : null,
                    type: data.type,
                    theme: data.theme || null,
                    infos: data.infos || null,
                    categoryId: data.categoryId,
                }),
            )
                .then(dispatch)
                .then(() => {
                    notify.success('Le temps de jeu a été mis à jour');
                })
                .then(onClose);
        },
        [dispatch, onClose, step, notify],
    );

    const onTalkSubmit = React.useCallback(
        (data: TalkFormData) => {
            if (step instanceof Talk === false) return;

            updateTalk(
                new UpdateTalk({
                    id: step.id,
                    duration: parseInt(data.duration),
                    label: data.label,
                }),
            )
                .then(dispatch)
                .then(() => {
                    notify.success('Le temps de parole a été mis à jour');
                })
                .then(onClose);
        },
        [dispatch, onClose, step, notify],
    );

    const onPauseSubmit = React.useCallback(
        (data: PauseFormData) => {
            if (step instanceof Pause === false) return;

            updatePause(
                new UpdatePause({
                    id: step.id,
                    duration: parseInt(data.duration),
                    label: data.label,
                }),
            )
                .then(dispatch)
                .then(() => {
                    notify.success('Le temps de pause a été mis à jour');
                })
                .then(onClose);
        },
        [dispatch, onClose, step, notify],
    );

    return (
        <StepModal
            title="Editer une étape"
            submitLabel="Enregistrer"
            cancelLabel="Annuler"
            isOpen={isOpen}
            onClose={onClose}
            onImproSubmit={onImproSubmit}
            onTalkSubmit={onTalkSubmit}
            onPauseSubmit={onPauseSubmit}
            type={type}
            impro={
                step instanceof Impro
                    ? {
                          duration: step.duration.toString(),
                          presentation: step.presentation.toString(),
                          debrief: step.debrief?.toString() || '',
                          players: step.players?.toString() || '',
                          type: step.type,
                          theme: step.theme || '',
                          infos: step.infos || '',
                          categoryId: step.category?.id || null,
                      }
                    : undefined
            }
            talk={
                step instanceof Talk
                    ? {
                          duration: step.duration.toString(),
                          label: step.label,
                      }
                    : undefined
            }
            pause={
                step instanceof Pause
                    ? {
                          duration: step.duration.toString(),
                          label: step.label,
                      }
                    : undefined
            }
        />
    );
};

export default UpdateStepModal;
