import { jwtDecode, type JwtPayload } from 'jwt-decode';
import * as LocalStorer from '../Infra/LocalStorer';

const key = 'impro-jwt';

const JWTError = class extends Error {
    constructor(message: string) {
        super(message);

        this.name = 'JWTError';
    }
};

const get = (): string => {
    const jwt = LocalStorer.get(key);

    if (typeof jwt !== 'string') {
        throw new JWTError('JWT not found');
    }

    return jwt;
};

const decode = (): JwtPayload => {
    return jwtDecode(get());
};

const set = (jwt: string): void => {
    return LocalStorer.set(key, jwt);
};

const remove = (): void => {
    return LocalStorer.remove(key);
};

export { get, set, remove, decode };
