type UpdateSchedulePositionOptions = {
    id: number;
    showId: number;
    position: number;
};

class UpdateSchedulePosition {
    id: number;
    showId: number;
    position: number;

    constructor({ id, showId, position }: UpdateSchedulePositionOptions) {
        this.id = id;
        this.showId = showId;
        this.position = position;

        Object.freeze(this);
    }
}

export default UpdateSchedulePosition;
