import * as React from 'react';
import PauseView from '@Stores/Views/Pause';
import { Divider } from '@mui/joy';
import {
    ScheduleItemBlock,
    ScheduleItemLine,
    ScheduleItemBlockProps,
} from './Item';
import makeSx from '@Guidelines/sx';

const sx = makeSx({
    divider: {
        flex: '1 0 auto',
    },
});

type Props = {
    pause: PauseView;
    onClick: (pause: PauseView) => void;
    onMove: (pause: PauseView, position: number | null) => void;
} & Omit<ScheduleItemBlockProps, 'onClick' | 'onMove' | 'children'>;

const ScheduleItemPause: React.ComponentType<Props> = ({
    pause,
    startDecorator,
    endDecorator,
    onClick,
    onMove,
}) => {
    const onBlockClick = React.useCallback(() => {
        onClick(pause);
    }, [pause, onClick]);

    const onBlockMove = React.useCallback(
        (position: number | null) => {
            onMove(pause, position);
        },
        [pause, onMove],
    );

    return (
        <ScheduleItemBlock
            onClick={onBlockClick}
            onMove={onBlockMove}
            startDecorator={startDecorator}
            endDecorator={endDecorator}
        >
            <ScheduleItemLine
                variant="plain"
                size="lg"
                duration={pause.duration}
            >
                <Divider sx={sx.divider}>{pause.label}</Divider>
            </ScheduleItemLine>
        </ScheduleItemBlock>
    );
};

export default ScheduleItemPause;
