import * as React from 'react';
import { type SigninUserSuccessAction } from '@Application/Actions/signinUserSuccess';
import { type SignoutUserSuccessAction } from '@Application/Actions/signoutUserSuccess';
import { type FetchShowsSuccessAction } from '@Application/Actions/fetchShowsSuccess';
import { type FetchShowSuccessAction } from '@Application/Actions/fetchShowSuccess';
import { type CreateShowSuccessAction } from '@Application/Actions/createShowSuccess';
import { type UpdateShowDetailsSuccessAction } from '@Application/Actions/updateShowDetailsSuccess';
import { type CreateImproSuccessAction } from '@Application/Actions/createImproSuccess';
import { type UpdateImproSuccessAction } from '@Application/Actions/updateImproSuccess';
import { type CreateTalkSuccessAction } from '@Application/Actions/createTalkSuccess';
import { type UpdateTalkSuccessAction } from '@Application/Actions/updateTalkSuccess';
import { type CreatePauseSuccessAction } from '@Application/Actions/createPauseSuccess';
import { type UpdatePauseSuccessAction } from '@Application/Actions/updatePauseSuccess';
import { type CreateScheduleSuccessAction } from '@Application/Actions/createScheduleSuccess';
import { type DeleteScheduleSuccessAction } from '@Application/Actions/deleteScheduleSuccess';
import { type DeleteImproSuccessAction } from '@Application/Actions/deleteImproSuccess';
import { type DeleteTalkSuccessAction } from '@Application/Actions/deleteTalkSuccess';
import { type DeletePauseSuccessAction } from '@Application/Actions/deletePauseSuccess';
import { UpdateSchedulePositionSuccessAction } from '@Application/Actions/updateSchedulePositionSuccess';
import ShowView from '../Views/Show';
import ShowsView from '../Views/Shows';
import ImproView from '@Stores/Views/Impro';
import TalkView from '@Stores/Views/Talk';
import PauseView from '@Stores/Views/Pause';
import register from '../register';

type Action =
    | SigninUserSuccessAction
    | SignoutUserSuccessAction
    | FetchShowsSuccessAction
    | FetchShowSuccessAction
    | CreateShowSuccessAction
    | UpdateShowDetailsSuccessAction
    | CreateImproSuccessAction
    | UpdateImproSuccessAction
    | CreateTalkSuccessAction
    | UpdateTalkSuccessAction
    | CreatePauseSuccessAction
    | UpdatePauseSuccessAction
    | CreateScheduleSuccessAction
    | DeleteScheduleSuccessAction
    | DeleteImproSuccessAction
    | DeleteTalkSuccessAction
    | DeletePauseSuccessAction
    | UpdateSchedulePositionSuccessAction;

type State = {
    shows?: ShowsView;
    show?: ShowView;
};

const initialState: State = {};

const store: React.Reducer<State, Action> = (state, action): State => {
    switch (action.type) {
        case 'FETCH_SHOWS_SUCCESS': {
            return {
                ...state,
                shows: new ShowsView(
                    action.payload.map((item) => new ShowView(item)),
                ),
            };
        }
        case 'FETCH_SHOW_SUCCESS': {
            return {
                ...state,
                show: new ShowView(action.payload),
            };
        }
        case 'CREATE_SHOW_SUCCESS': {
            const { shows } = state;

            return {
                ...state,
                shows: shows?.addShow(new ShowView(action.payload)) ?? shows,
            };
        }
        case 'UPDATE_SHOW_DETAILS_SUCCESS': {
            const { show, shows } = state;
            const { id } = action.payload;

            return {
                ...state,
                show: show?.id === id ? new ShowView(action.payload) : show,
                shows: shows?.updateShow(new ShowView(action.payload)) ?? shows,
            };
        }
        case 'CREATE_IMPRO_SUCCESS': {
            const { show, shows } = state;
            const { showId } = action.payload;

            const impro = new ImproView(action.payload);
            const existingShow = shows?.findBydId(showId);

            return {
                ...state,
                show: show?.id === showId ? show.addImpro(impro) : show,
                shows:
                    shows && existingShow
                        ? shows.updateShow(existingShow.addImpro(impro))
                        : shows,
            };
        }
        case 'UPDATE_IMPRO_SUCCESS': {
            const { show, shows } = state;
            const { showId } = action.payload;

            const impro = new ImproView(action.payload);
            const existingShow = shows?.findBydId(showId);

            return {
                ...state,
                show: show?.id === showId ? show.updateImpro(impro) : show,
                shows:
                    shows && existingShow
                        ? shows.updateShow(existingShow.updateImpro(impro))
                        : shows,
            };
        }
        case 'CREATE_TALK_SUCCESS': {
            const { show, shows } = state;
            const { showId } = action.payload;

            const talk = new TalkView(action.payload);
            const existingShow = shows?.findBydId(showId);

            return {
                ...state,
                show: show?.id === showId ? show.addTalk(talk) : show,
                shows:
                    shows && existingShow
                        ? shows.updateShow(existingShow.addTalk(talk))
                        : shows,
            };
        }
        case 'UPDATE_TALK_SUCCESS': {
            const { show, shows } = state;
            const { showId } = action.payload;

            const talk = new TalkView(action.payload);
            const existingShow = shows?.findBydId(showId);

            return {
                ...state,
                show: show?.id === showId ? show.updateTalk(talk) : show,
                shows:
                    shows && existingShow
                        ? shows.updateShow(existingShow.updateTalk(talk))
                        : shows,
            };
        }
        case 'CREATE_PAUSE_SUCCESS': {
            const { show, shows } = state;
            const { showId } = action.payload;

            const pause = new PauseView(action.payload);
            const existingShow = shows?.findBydId(showId);

            return {
                ...state,
                show: show?.id === showId ? show.addPause(pause) : show,
                shows:
                    shows && existingShow
                        ? shows.updateShow(existingShow.addPause(pause))
                        : shows,
            };
        }
        case 'UPDATE_PAUSE_SUCCESS': {
            const { show, shows } = state;
            const { showId } = action.payload;

            const pause = new PauseView(action.payload);
            const existingShow = shows?.findBydId(showId);

            return {
                ...state,
                show: show?.id === showId ? show.updatePause(pause) : show,
                shows:
                    shows && existingShow
                        ? shows.updateShow(existingShow.updatePause(pause))
                        : shows,
            };
        }
        case 'CREATE_SCHEDULE_SUCCESS': {
            const { show, shows } = state;
            const { id } = action.payload;

            return {
                ...state,
                show: show?.id === id ? new ShowView(action.payload) : show,
                shows: shows?.updateShow(new ShowView(action.payload)) ?? shows,
            };
        }
        case 'DELETE_SCHEDULE_SUCCESS': {
            const { show, shows } = state;
            const { id } = action.payload;

            return {
                ...state,
                show: show?.id === id ? new ShowView(action.payload) : show,
                shows: shows?.updateShow(new ShowView(action.payload)) ?? shows,
            };
        }
        case 'UPDATE_SCHEDULE_POSITION_SUCCESS': {
            const { show, shows } = state;
            const { id } = action.payload;

            return {
                ...state,
                show: show?.id === id ? new ShowView(action.payload) : show,
                shows: shows?.updateShow(new ShowView(action.payload)) ?? shows,
            };
        }
        case 'DELETE_IMPRO_SUCCESS': {
            const { show, shows } = state;
            const { id, showId } = action.payload;

            const existingShow = shows?.findBydId(showId);

            return {
                ...state,
                show: show?.id === showId ? show.removeImpro(id) : show,
                shows:
                    shows && existingShow
                        ? shows.updateShow(existingShow.removeImpro(id))
                        : shows,
            };
        }
        case 'DELETE_TALK_SUCCESS': {
            const { show, shows } = state;
            const { id, showId } = action.payload;

            const existingShow = shows?.findBydId(showId);

            return {
                ...state,
                show: show?.id === showId ? show.removeTalk(id) : show,
                shows:
                    shows && existingShow
                        ? shows.updateShow(existingShow.removeTalk(id))
                        : shows,
            };
        }
        case 'DELETE_PAUSE_SUCCESS': {
            const { show, shows } = state;
            const { id, showId } = action.payload;

            const existingShow = shows?.findBydId(showId);

            return {
                ...state,
                show: show?.id === showId ? show.removePause(id) : show,
                shows:
                    shows && existingShow
                        ? shows.updateShow(existingShow.removePause(id))
                        : shows,
            };
        }
        case 'SIGNIN_USER_SUCCESS': {
            return initialState;
        }
        case 'SIGNOUT_USER_SUCCESS': {
            return initialState;
        }
        default:
            return state;
    }
};

const useStore = register('shows', store, initialState);

export default useStore;
