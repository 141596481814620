type UpdatePauseOptions = {
    id: number;
    duration: number;
    label: string;
};

class UpdatePause {
    id: number;
    duration: number;
    label: string;

    constructor({ id, duration, label }: UpdatePauseOptions) {
        this.id = id;
        this.duration = duration;
        this.label = label;

        Object.freeze(this);
    }
}

export default UpdatePause;
