type UpdateShowDetailsOptions = {
    id: number;
    name: string;
    date: Date;
};

class UpdateShowDetails {
    id: number;
    name: string;
    date: Date;

    constructor({ id, name, date }: UpdateShowDetailsOptions) {
        this.id = id;
        this.name = name;
        this.date = date;

        Object.freeze(this);
    }
}

export default UpdateShowDetails;
