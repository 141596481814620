type DeleteImproOptions = {
    id: number;
};

class DeleteImpro {
    id: number;

    constructor({ id }: DeleteImproOptions) {
        this.id = id;

        Object.freeze(this);
    }
}

export default DeleteImpro;
