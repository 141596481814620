type FetchShowOptions = {
    id: number;
};

class FetchShow {
    id: number;

    constructor({ id }: FetchShowOptions) {
        this.id = id;

        Object.freeze(this);
    }
}

export default FetchShow;
