import * as Api from '../Domain/Api';
import UpdateTalk from './Commands/UpdateTalk';
import updateTalkSuccess, {
    type UpdateTalkPayload,
} from './Actions/updateTalkSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const updateTalk = async (command: UpdateTalk) => {
    const payload = await Api.patch<UpdateTalkPayload>(
        `${parameters.api.host}/talks/${command.id}`,
        {
            duration: command.duration,
            label: command.label,
        },
        {
            jwt: JWTStorer.get(),
        },
    );

    return updateTalkSuccess(payload);
};

export default updateTalk;
