import * as React from 'react';
import Context from './Context';

export type ContextNotification = {
    message: React.ReactNode;
    type: 'danger' | 'neutral' | 'success' | 'warning';
};

type Props = {
    NotificationComponent: React.ComponentType<ContextNotification>;
    children: React.ReactNode;
};

const Provider: React.ComponentType<Props> = ({
    NotificationComponent,
    children,
}) => {
    const [notifications, setNotifications] = React.useState<
        Array<ContextNotification>
    >([]);

    const onNotification = React.useCallback(
        (notification: ContextNotification) => {
            setNotifications((prev) => [...prev, notification]);
        },
        [],
    );

    const onMessage = React.useCallback(
        (message: React.ReactNode) =>
            onNotification({ message, type: 'neutral' }),
        [onNotification],
    );

    const onSuccess = React.useCallback(
        (message: React.ReactNode) =>
            onNotification({ message, type: 'success' }),
        [onNotification],
    );

    const onWarning = React.useCallback(
        (message: React.ReactNode) =>
            onNotification({ message, type: 'warning' }),
        [onNotification],
    );

    const onError = React.useCallback(
        (message: React.ReactNode) =>
            onNotification({ message, type: 'danger' }),
        [onNotification],
    );

    return (
        <React.Fragment>
            <Context.Provider
                value={{
                    message: onMessage,
                    success: onSuccess,
                    warning: onWarning,
                    error: onError,
                }}
            >
                {children}
            </Context.Provider>
            {notifications.map((notification, index) => (
                <NotificationComponent key={index} {...notification} />
            ))}
        </React.Fragment>
    );
};

export default Provider;
