import * as React from 'react';

export type Shape = {
    message: (message: React.ReactNode) => void;
    success: (message: React.ReactNode) => void;
    warning: (message: React.ReactNode) => void;
    error: (message: React.ReactNode) => void;
};

const context = React.createContext<Shape | void>(undefined);

export default context;
