import React from 'react';
import { useIntl } from 'react-intl';
import { Text, View, Image } from '@react-pdf/renderer';
import ImproView from '@Stores/Views/Impro';
import {
    Category as CategoryIcon,
    Palette as PaletteIcon,
    Group as GroupIcon,
    Timer as TimerIcon,
    Info as InfoIcon,
    Inventory2 as Inventory2Icon,
} from '@mui/icons-material';
import SVGIcon from './SVGIcon';
import { useCloudinary } from '@Guidelines/Image';
import useStyles from './useStyles';

type Props = {
    impro: ImproView;
    position: number | null;
};

const Impro: React.ComponentType<Props> = ({ impro, position }) => {
    const intl = useIntl();
    const cloudinary = useCloudinary();

    const improTypeColor = React.useMemo(() => {
        switch (impro.type) {
            case 'COMPARATIVE':
                return 'warning';
            case 'MIXED':
                return 'success';
            case 'WARMUP':
                return 'primary';
            default:
                return 'neutral';
        }
    }, [impro]);

    const improTypeLabel = React.useMemo(() => {
        switch (impro.type) {
            case 'COMPARATIVE':
                return 'Comparée';
            case 'MIXED':
                return 'Mixte';
            case 'WARMUP':
                return 'Warmup';
            default:
                return null;
        }
    }, [impro]);

    const styles = useStyles(({ theme, fontSize }) => ({
        root: {
            height: '50%',
            width: '50%',
            fontSize: fontSize(1),
            border: `1px solid ${theme.palette.neutral[200]}`,
        },
        header: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette[improTypeColor][300],
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        headerNumber: {
            opacity: 0.75,
            fontSize: fontSize(1.25),
            borderRadius: '50%',
            height: '30px',
            width: '30px',
            fontFamily: 'Limelight',
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            alignItems: 'center',
        },
        headerNumberIcon: {
            height: '15px',
            width: '15px',
        },
        headerLogo: {
            height: '25px',
        },
        headerType: {
            fontSize: fontSize(0.9),
            padding: theme.spacing(0.5, 0.8),
            backgroundColor: theme.palette[improTypeColor][100],
            color: theme.palette[improTypeColor][500],
            borderRadius: theme.radius.xs,
        },
        body: {
            flexDirection: 'row',
            flex: '1 1 auto',
        },
        side: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.primary[50],
            width: '30%',
        },
        sideItem: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: theme.spacing(1),
        },
        sideItemIcon: {
            height: '15px',
            width: '15px',
            marginRight: theme.spacing(1),
        },
        sideItemText: {
            flex: '1 1 auto',
        },
        main: {
            padding: theme.spacing(1),
            flex: '1 1 auto',
            flexDirection: 'column',
        },
        part: {
            flexDirection: 'column',
            flex: '1 1 auto',
        },
        partTitle: {
            flexDirection: 'row',
            borderBottom: `1px solid ${theme.palette.neutral[200]}`,
            padding: theme.spacing(0.5),
        },
        partTitleIcon: {
            height: '15px',
            width: '15px',
            marginRight: theme.spacing(1),
        },
        partTitleText: {
            flex: '1 1 auto',
            textAlign: 'left',
            fontFamily: 'Limelight',
            fontSize: fontSize(0.95),
        },
        partContent: {
            flex: '1 1 auto',
            padding: theme.spacing(1, 0),
            justifyContent: 'center',
            alignItems: 'center',
        },
        footer: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.neutral[50],
            color: theme.palette.neutral[800],
            borderTop: `1px solid ${theme.palette.neutral[200]}`,
            fontSize: fontSize(0.75),
            flexDirection: 'row',
        },
        footerIcon: {
            height: '12px',
            width: '12px',
            marginRight: theme.spacing(1),
        },
        footerText: {
            flex: '1 1 0%',
        },
    }));

    return (
        <View style={styles.root}>
            <View style={styles.header}>
                <View style={styles.headerNumber}>
                    {typeof position === 'number' ? (
                        <Text>{position}</Text>
                    ) : (
                        <SVGIcon
                            icon={<Inventory2Icon />}
                            style={styles.headerNumberIcon}
                        />
                    )}
                </View>
                <View>
                    <Image
                        style={styles.headerLogo}
                        src={cloudinary.image('/logo/inline-transparent.png')}
                    />
                </View>
                <View style={styles.headerType}>
                    <Text>{improTypeLabel}</Text>
                </View>
            </View>
            <View style={styles.body}>
                <View style={styles.side}>
                    <View style={styles.sideItem}>
                        <SVGIcon
                            style={styles.sideItemIcon}
                            icon={<GroupIcon />}
                        />
                        <View style={styles.sideItemText}>
                            <Text>{impro.players || 'Illimité'}</Text>
                        </View>
                    </View>
                    <View style={styles.sideItem}>
                        <SVGIcon
                            style={styles.sideItemIcon}
                            icon={<TimerIcon />}
                        />
                        <View style={styles.sideItemText}>
                            <Text>
                                {intl.formatNumber(impro.duration, {
                                    style: 'unit',
                                    unit: 'minute',
                                    unitDisplay: 'short',
                                })}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.main}>
                    <View style={styles.part}>
                        <View style={styles.partTitle}>
                            <SVGIcon
                                style={styles.partTitleIcon}
                                icon={<CategoryIcon />}
                            />
                            <Text style={styles.partTitleText}>Catégorie</Text>
                        </View>
                        <View style={styles.partContent}>
                            <Text>{impro.category?.name}</Text>
                        </View>
                    </View>
                    <View style={styles.part}>
                        <View style={styles.partTitle}>
                            <SVGIcon
                                style={styles.partTitleIcon}
                                icon={<PaletteIcon />}
                            />
                            <Text style={styles.partTitleText}>Thème</Text>
                        </View>
                        <View style={styles.partContent}>
                            <Text>{impro.theme}</Text>
                        </View>
                    </View>
                </View>
            </View>
            {impro.infos && (
                <View style={styles.footer}>
                    <SVGIcon
                        style={styles.footerIcon}
                        icon={<InfoIcon sx={{ display: 'none' }} />}
                    />
                    <Text style={styles.footerText}>{impro.infos}</Text>
                </View>
            )}
        </View>
    );
};

export default Impro;
