import React from 'react';
import { Font, Document } from '@react-pdf/renderer';
import ShowView, { ShowStep } from '@Stores/Views/Show';
import Impro from './Impro';
import Back from './Back';
import UserView from '@Stores/Views/User';
import ImproView from '@Stores/Views/Impro';
import Providers from '../../../../../Providers';
import Schedule from './Schedule';
import FrontBackPage from './FrontBackPage';

Font.register({
    family: 'Inter',
    src: '/fonts/Inter/Inter-VariableFont_slnt,wght.ttf',
});
Font.register({
    family: 'Limelight',
    src: '/fonts/Limelight/Limelight-Regular.ttf',
});

type Props = {
    user: UserView;
    show: ShowView;
    fileName: string;
    onRender?: () => void;
};

const PDF: React.ComponentType<Props> = ({
    fileName,
    show,
    user,
    onRender,
}) => {
    const improsByGroupsCount = 4;

    const paginateSteps = (steps: Array<ShowStep>) =>
        steps
            .filter((step) => step instanceof ImproView)
            .reduce(
                (acc, step) => {
                    const lastGroup = acc[acc.length - 1];

                    if (
                        !lastGroup ||
                        lastGroup.length === improsByGroupsCount
                    ) {
                        return acc.concat([[step]]);
                    }

                    return acc.slice(0, -1).concat([lastGroup.concat(step)]);
                },
                [] as Array<Array<ShowStep>>,
            );

    const renderStepFront = (step: ShowStep) => {
        if (step instanceof ImproView) {
            const schedule = show.findSchedule(step);

            return (
                <Impro
                    key={JSON.stringify(step)}
                    impro={step}
                    position={
                        schedule
                            ? show.schedules
                                  .filterByType(step.getScheduleType())
                                  .orderByPosition()
                                  .schedules.indexOf(schedule) + 1
                            : null
                    }
                />
            );
        }

        return <Back key={JSON.stringify(step)} show={show} />;
    };

    const renderStepBack = (step: ShowStep) => {
        return <Back key={JSON.stringify(step)} show={show} />;
    };

    return (
        <Providers>
            <Document
                title={fileName}
                author={user.email}
                creator="improkit.com"
                subject={show.name}
                onRender={onRender}
            >
                <FrontBackPage
                    front={<Schedule show={show} />}
                    back={<Back show={show} fullWidth />}
                />
                {paginateSteps(show.findUsedSteps()).map((pageSteps, index) => (
                    <FrontBackPage
                        key={index}
                        front={pageSteps.map(renderStepFront)}
                        back={pageSteps.map(renderStepBack)}
                    />
                ))}
                {paginateSteps(show.findUnusedSteps()).map(
                    (pageSteps, index) => (
                        <FrontBackPage
                            key={index}
                            front={pageSteps.map(renderStepFront)}
                            back={pageSteps.map(renderStepBack)}
                        />
                    ),
                )}
                <FrontBackPage
                    front={<Schedule show={show} />}
                    back={<Back show={show} fullWidth />}
                />
                <FrontBackPage
                    front={<Schedule show={show} />}
                    back={<Back show={show} fullWidth />}
                />
            </Document>
        </Providers>
    );
};

export default PDF;
