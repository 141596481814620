import * as React from 'react';
import ImproView from '@Stores/Views/Impro';
import {
    List,
    ListItem,
    ListItemDecorator,
    ListItemContent,
    Avatar,
    ListDivider,
    Chip,
} from '@mui/joy';
import {
    Group as GroupIcon,
    Palette as PaletteIcon,
    Category as CategoryIcon,
} from '@mui/icons-material';
import {
    ScheduleItemBlock,
    ScheduleItemLine,
    type ScheduleItemBlockProps,
} from './Item';
import makeSx from '@Guidelines/sx';

const sx = makeSx({
    type: {
        writingMode: 'vertical-rl',
        textAlign: 'center',
        padding: 0,
    },
    list: (theme) => ({
        flexDirection: 'column',
        justifyContent: 'stretch',
        gap: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    }),
    listItem: (theme) => ({
        flex: '1 1 0',
        gap: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            gap: theme.spacing(1),
        },
    }),
});

type Props = {
    impro: ImproView;
    onClick: (impro: ImproView) => void;
    onMove: (impro: ImproView, position: number | null) => void;
} & Omit<ScheduleItemBlockProps, 'onClick' | 'onMove' | 'children'>;

const ScheduleItemImpro: React.ComponentType<Props> = ({
    impro,
    startDecorator,
    endDecorator,
    onClick,
    onMove,
}) => {
    const onBlockClick = React.useCallback(() => {
        onClick(impro);
    }, [impro, onClick]);

    const onBlockMove = React.useCallback(
        (position: number | null) => {
            onMove(impro, position);
        },
        [impro, onMove],
    );

    const multiplicatorInfo = React.useMemo(() => {
        if (impro.getMultiplicator() !== 1) {
            return `x${impro.getMultiplicator()}`;
        }

        return null;
    }, [impro]);

    const improColor = React.useMemo(() => {
        switch (impro.type) {
            case 'COMPARATIVE':
                return 'warning';
            case 'MIXED':
                return 'success';
            case 'WARMUP':
                return 'primary';
            default:
                return 'neutral';
        }
    }, [impro]);

    const improLabel = React.useMemo(() => {
        switch (impro.type) {
            case 'COMPARATIVE':
                return 'Comparée';
            case 'MIXED':
                return 'Mixte';
            case 'WARMUP':
                return 'Warmup';
            default:
                return null;
        }
    }, [impro]);

    return (
        <ScheduleItemBlock
            onClick={onBlockClick}
            onMove={onBlockMove}
            startDecorator={startDecorator}
            endDecorator={endDecorator}
        >
            {impro.presentation !== 0 && (
                <ScheduleItemLine
                    variant="soft"
                    size="sm"
                    duration={impro.presentation}
                >
                    Présentation
                </ScheduleItemLine>
            )}
            <ScheduleItemLine
                variant="outlined"
                size="lg"
                duration={
                    multiplicatorInfo
                        ? {
                              value: impro.duration,
                              decorator: multiplicatorInfo,
                          }
                        : impro.duration
                }
                infos={impro.infos}
            >
                <Chip size="lg" variant="plain" color={improColor} sx={sx.type}>
                    {improLabel}
                </Chip>
                <List sx={sx.list} orientation="horizontal">
                    <ListItem sx={sx.listItem}>
                        <ListItemDecorator>
                            <Avatar>
                                <GroupIcon />
                            </Avatar>
                        </ListItemDecorator>
                        <ListItemContent>
                            {impro.players || 'Illimité'}
                        </ListItemContent>
                    </ListItem>
                    <ListDivider />
                    <ListItem sx={sx.listItem}>
                        <ListItemDecorator component={Avatar}>
                            <CategoryIcon />
                        </ListItemDecorator>
                        <ListItemContent>
                            {impro.category?.name || '-'}
                        </ListItemContent>
                    </ListItem>
                    <ListDivider />
                    <ListItem sx={sx.listItem}>
                        <ListItemDecorator component={Avatar}>
                            <PaletteIcon />
                        </ListItemDecorator>
                        <ListItemContent>{impro.theme || '-'}</ListItemContent>
                    </ListItem>
                </List>
            </ScheduleItemLine>
            {impro.debrief !== 0 && (
                <ScheduleItemLine
                    variant="soft"
                    size="sm"
                    duration={impro.debrief}
                >
                    Débrief
                </ScheduleItemLine>
            )}
        </ScheduleItemBlock>
    );
};

export default ScheduleItemImpro;
