import * as Api from '../Domain/Api';
import CreateShow from './Commands/CreateShow';
import createShowSuccess, {
    type CreateShowPayload,
} from './Actions/createShowSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const createShow = async (command: CreateShow) => {
    const payload = await Api.post<CreateShowPayload>(
        `${parameters.api.host}/shows`,
        {
            name: command.name,
            date: command.date,
        },
        {
            jwt: JWTStorer.get(),
        },
    );

    return createShowSuccess(payload);
};

export default createShow;
