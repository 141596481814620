import * as React from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Dropdown,
    Menu,
    MenuButton,
    MenuItem,
    ListItemDecorator,
    Link,
    Sheet,
    Tooltip,
    Button,
    IconButton,
    Stack,
} from '@mui/joy';
import {
    ArrowDropDown as ArrowDropDownIcon,
    Logout as LogoutIcon,
    AccountCircle as AccountCircleIcon,
    TheaterComedy as TheaterComedyIcon,
    Menu as MenuIcon,
    Person as PersonIcon,
} from '@mui/icons-material';
import makeSx, { combineSX } from '@Guidelines/sx';
import Image, { useCloudinary } from '@Guidelines/Image';
import signoutUser from '@Application/signoutUser';
import SignoutUser from '@Application/Commands/SignoutUser';
import fetchUser from '@Application/fetchUser';
import FetchUser from '@Application/Queries/FetchUser';
import { useDispatch } from '@Stores/index';
import useUserStore from '@Stores/Stores/user';
import Hidden from '@Guidelines/Hidden';
import Sidebar from './Sidebar';

const sx = makeSx({
    root: (theme) => ({
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(2),
        padding: theme.spacing(1, 2),
        zIndex: theme.zIndex.popup - 1,
        alignItems: 'center',
        background: 'transparent',
    }),
    center: (theme) => ({
        width: '100px',
        height: '50px',
        boxSizing: 'content-box',
        color: theme.palette.background.surface,
        flex: '0 0 auto',
        padding: theme.spacing(0.5, 0),
        [theme.breakpoints.up('md')]: {
            width: '140px',
            height: '70px',
            padding: theme.spacing(1, 0),
        },
    }),
    side: {
        flex: '1 1 0%',
    },
    left: (theme) => ({
        textAlign: 'left',
    }),
    right: (theme) => ({
        textAlign: 'right',
    }),
    logoLink: {
        height: '100%',
        width: '100%',
    },
    menuButtonLink: {
        color: 'inherit',
    },
    menu: {},
});

type Props = {};

const Navbar: React.ComponentType<Props> = () => {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const cloudinary = useCloudinary();
    const navigate = useNavigate();

    const userStore = useUserStore();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (userStore.user) return;

        fetchUser(new FetchUser()).then(dispatch);
    }, [dispatch, userStore.user]);

    const onMenuButtonClick = React.useCallback(() => {
        setIsSidebarOpen(true);
    }, []);

    const onSideBarClose = React.useCallback(() => {
        setIsSidebarOpen(false);
    }, []);

    const onLogoutClick = React.useCallback(() => {
        signoutUser(new SignoutUser())
            .then((action) => {
                navigate('/');

                return action;
            })
            .then(dispatch);
    }, [navigate, dispatch]);

    return (
        <Sheet sx={sx.root} invertedColors variant="solid">
            <Stack direction="row" spacing={2} sx={combineSX(sx.side, sx.left)}>
                <Tooltip title="Menu" placement="bottom" arrow>
                    <IconButton
                        variant="plain"
                        size="lg"
                        onClick={onMenuButtonClick}
                    >
                        <MenuIcon />
                    </IconButton>
                </Tooltip>
                <Hidden breakpoints={{ md: false }}>
                    <Tooltip title="Vos spectacles" placement="bottom" arrow>
                        <Link
                            component={RouterLink}
                            to="/_/shows"
                            underline="none"
                        >
                            <Button
                                variant="soft"
                                size="lg"
                                startDecorator={<TheaterComedyIcon />}
                            >
                                Mes spectacles
                            </Button>
                        </Link>
                    </Tooltip>
                </Hidden>
            </Stack>
            <Box sx={sx.center}>
                <Link component={RouterLink} to="/_" sx={sx.logoLink}>
                    <Image
                        src={cloudinary.image('/logo/inline-transparent.png')}
                        alt="Improkit logo"
                    />
                </Link>
            </Box>
            <Box sx={combineSX(sx.side, sx.right)}>
                <Hidden breakpoints={{ sm: false }}>
                    <Dropdown>
                        <MenuButton
                            startDecorator={<AccountCircleIcon />}
                            endDecorator={<ArrowDropDownIcon />}
                            variant="plain"
                            size="lg"
                        >
                            <Hidden breakpoints={{ md: false }}>
                                {userStore.user?.email}
                            </Hidden>
                            <Hidden breakpoints={{ xs: false, md: true }}>
                                {userStore.user?.getUsername()}
                            </Hidden>
                        </MenuButton>
                        <Menu placement="bottom-end">
                            <MenuItem component={RouterLink} to="/_/profile">
                                <ListItemDecorator>
                                    <PersonIcon />
                                </ListItemDecorator>
                                Mon profil
                            </MenuItem>
                            <MenuItem
                                onClick={onLogoutClick}
                                variant="soft"
                                color="danger"
                            >
                                <ListItemDecorator>
                                    <LogoutIcon />
                                </ListItemDecorator>
                                Déconnexion
                            </MenuItem>
                        </Menu>
                    </Dropdown>
                </Hidden>
            </Box>
            <Sidebar
                user={userStore.user}
                isOpen={isSidebarOpen}
                onClose={onSideBarClose}
                onLogoutClick={onLogoutClick}
            />
        </Sheet>
    );
};

export default Navbar;
