import Category from './Category';

class Categories {
    categories: Array<Category>;

    constructor(categories: Array<Category>) {
        this.categories = categories;

        Object.freeze(this);
    }

    orderByUserId(asc?: boolean): Categories {
        const multiplicator = asc === false ? -1 : 1;

        const orderedCategories = this.categories.sort((a, b) => {
            return ((a.userId || 0) - (b.userId || 0)) * multiplicator;
        });

        return new Categories(orderedCategories);
    }

    orderByName(asc?: boolean): Categories {
        const multiplicator = asc === false ? -1 : 1;

        const orderedCategories = this.categories.sort((a, b) => {
            return a.name.localeCompare(b.name) * multiplicator;
        });

        return new Categories(orderedCategories);
    }

    findById(id: number): Category | undefined {
        return this.categories.find((category) => category.id === id);
    }

    filterByIds(ids: Array<number>): Categories {
        const filteredCategories = this.categories.filter((category) =>
            ids.includes(category.id),
        );

        return new Categories(filteredCategories);
    }

    filterByText(text: string): Categories {
        const textParts = text.split(' ');

        const filteredCategories = this.categories.filter((category) => {
            return textParts.every((textPart) =>
                category.name.toLowerCase().includes(textPart.toLowerCase()),
            );
        });

        return new Categories(filteredCategories);
    }
}

export default Categories;
