type Declaration<V> = {
    selector: () => V;
    check: (value: V) => boolean;
    run: () => void;
};

const useWaitForState = <T>({
    selector,
    check,
    run,
}: Declaration<T>): T | null => {
    const value = selector();

    if (!check(value)) {
        run();

        return null;
    }

    return value;
};

export default useWaitForState;
