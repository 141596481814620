import Pause from './Pause';

class Pauses {
    pauses: Array<Pause>;

    constructor(pauses: Array<Pause>) {
        this.pauses = pauses;

        Object.freeze(this);
    }

    findById(id: number): Pause {
        const pause = this.pauses.find((pause) => pause.id === id);

        if (!pause) {
            throw new Error(`Pause with id ${id} not found`);
        }

        return pause;
    }

    addPause(pause: Pause): Pauses {
        return new Pauses(this.pauses.concat([pause]));
    }

    updatePause(pause: Pause): Pauses {
        return new Pauses(
            this.pauses.map((existingPause) =>
                existingPause.id === pause.id ? pause : existingPause,
            ),
        );
    }

    removePause(id: number): Pauses {
        return new Pauses(this.pauses.filter((pause) => pause.id !== id));
    }
}

export default Pauses;
