export type UpdatePausePayload = {
    id: number;
    showId: number;
    duration: number;
    label: string;
};

export type UpdatePauseSuccessAction = {
    type: 'UPDATE_PAUSE_SUCCESS';
    payload: UpdatePausePayload;
};

const updatePauseSuccess = (
    payload: UpdatePausePayload,
): UpdatePauseSuccessAction => {
    return {
        type: 'UPDATE_PAUSE_SUCCESS',
        payload: payload,
    };
};

export default updatePauseSuccess;
