type DeleteScheduleOptions = {
    id: number;
    showId: number;
};

class DeleteSchedule {
    id: number;
    showId: number;

    constructor({ id, showId }: DeleteScheduleOptions) {
        this.id = id;
        this.showId = showId;

        Object.freeze(this);
    }
}

export default DeleteSchedule;
