import * as Api from '../Domain/Api';
import SigninUser from './Commands/SigninUser';
import signinUserSuccess, {
    type SigninUserPayload,
} from './Actions/signinUserSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const signinUser = async (command: SigninUser) => {
    const payload = await Api.post<SigninUserPayload>(
        `${parameters.api.host}/access/signin`,
        {
            email: command.email,
            password: command.password,
        },
    );

    JWTStorer.set(payload.token);

    return signinUserSuccess(payload);
};

export default signinUser;
