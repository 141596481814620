import * as React from 'react';
import {
    Autocomplete,
    FormControl,
    FormLabel,
    Input,
    Option,
    Select,
    Textarea,
    Typography,
} from '@mui/joy';
import {
    Timer as TimerIcon,
    Group as GroupIcon,
    Palette as PaletteIcon,
    Info as InfoIcon,
    DoubleArrow as DoubleArrowIcon,
    Category as CategoryIcon,
    UnfoldMore as UnfoldMoreIcon,
} from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { useDispatch, useWaitForState } from '@Stores/index';
import useCategoriesStore from '@Stores/Stores/categories';
import fetchCategories from '@Application/fetchCategories';
import FetchCategories from '@Application/Queries/FetchCategories';

export type Data = {
    duration: string;
    presentation: string;
    debrief: string;
    players: string;
    type: string;
    theme: string;
    infos: string;
    categoryId: number | null;
};

type Props = {
    data: Data;
    onChange: (data: Data) => void;
};

const Impro: React.ComponentType<Props> = ({ data, onChange }) => {
    const intl = useIntl();
    const categoriesStore = useCategoriesStore();
    const dispatch = useDispatch();

    const categories = useWaitForState({
        selector: () => categoriesStore.categories,
        check: (categories) => !!categories,
        run: () => {
            fetchCategories(new FetchCategories()).then(dispatch);
        },
    });

    const onDurationChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
                ...data,
                duration: e.target.value,
            });
        },
        [data, onChange],
    );

    const onPresentationChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
                ...data,
                presentation: e.target.value,
            });
        },
        [data, onChange],
    );

    const onDebriefChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
                ...data,
                debrief: e.target.value,
            });
        },
        [data, onChange],
    );

    const onPlayersChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
                ...data,
                players: e.target.value,
            });
        },
        [data, onChange],
    );

    const onTypeChange = React.useCallback(
        (event: React.SyntheticEvent | null, newValue: string | null) => {
            onChange({
                ...data,
                type: newValue || '',
            });
        },
        [data, onChange],
    );

    const onCategoryChange = React.useCallback(
        (event: React.SyntheticEvent | null, newValue: number | null) => {
            onChange({
                ...data,
                categoryId: newValue,
            });
        },
        [data, onChange],
    );

    const onThemeChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
                ...data,
                theme: e.target.value,
            });
        },
        [data, onChange],
    );

    const onInfosChange = React.useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            onChange({
                ...data,
                infos: e.target.value,
            });
        },
        [data, onChange],
    );

    const findDurationUnit = React.useCallback(
        (duration: string) => {
            return intl
                .formatNumberToParts(parseInt(duration), {
                    style: 'unit',
                    unit: 'minute',
                    unitDisplay: 'short',
                })
                .find(({ type }) => type === 'unit')?.value;
        },
        [intl],
    );

    const computeTypeColor = React.useCallback((type: string) => {
        switch (type) {
            case 'COMPARATIVE':
                return 'warning';
            case 'MIXED':
                return 'success';
            case 'WARMUP':
                return 'primary';
            default:
                return 'neutral';
        }
    }, []);

    const types = {
        MIXED: 'Mixte',
        COMPARATIVE: 'Comparée',
        WARMUP: 'Warmup',
    };

    return (
        <React.Fragment>
            <FormControl required orientation="horizontal">
                <FormLabel sx={{ flex: '1 1 100%' }}>
                    <Typography
                        startDecorator={<DoubleArrowIcon />}
                        textColor="inherit"
                    >
                        Type
                    </Typography>
                </FormLabel>
                <Select
                    required
                    value={data.type}
                    onChange={onTypeChange}
                    sx={{ width: '250px' }}
                    color={computeTypeColor(data.type)}
                >
                    {Object.entries(types).map(([type, label]) => (
                        <Option value={type} key={type}>
                            <Typography color={computeTypeColor(type)}>
                                {label}
                            </Typography>
                        </Option>
                    ))}
                </Select>
            </FormControl>
            <FormControl required orientation="horizontal">
                <FormLabel sx={{ flex: '1 1 100%' }}>
                    <Typography
                        startDecorator={<TimerIcon />}
                        textColor="inherit"
                    >
                        Durée
                    </Typography>
                </FormLabel>
                <Input
                    sx={{ width: '250px' }}
                    value={data.duration}
                    onChange={onDurationChange}
                    type="number"
                    slotProps={{
                        input: {
                            min: 1,
                            step: 1,
                        },
                    }}
                    endDecorator={findDurationUnit(data.duration)}
                />
            </FormControl>
            <FormControl required orientation="horizontal">
                <FormLabel sx={{ flex: '1 1 100%' }}>
                    <Typography
                        startDecorator={<TimerIcon />}
                        textColor="inherit"
                    >
                        Présentation
                    </Typography>
                </FormLabel>
                <Input
                    sx={{ width: '250px' }}
                    value={data.presentation}
                    onChange={onPresentationChange}
                    type="number"
                    slotProps={{
                        input: {
                            min: 0,
                            step: 1,
                        },
                    }}
                    endDecorator={findDurationUnit(data.presentation)}
                />
            </FormControl>
            <FormControl required orientation="horizontal">
                <FormLabel sx={{ flex: '1 1 100%' }}>
                    <Typography
                        startDecorator={<TimerIcon />}
                        textColor="inherit"
                    >
                        Débrief
                    </Typography>
                </FormLabel>
                <Input
                    sx={{ width: '250px' }}
                    value={data.debrief}
                    onChange={onDebriefChange}
                    type="number"
                    slotProps={{
                        input: {
                            min: 0,
                            step: 1,
                        },
                    }}
                    endDecorator={findDurationUnit(data.debrief)}
                />
            </FormControl>
            <FormControl orientation="horizontal">
                <FormLabel sx={{ flex: '1 1 100%' }}>
                    <Typography
                        startDecorator={<GroupIcon />}
                        textColor="inherit"
                    >
                        Joueurs
                    </Typography>
                </FormLabel>
                <Input
                    sx={{ width: '250px' }}
                    value={data.players}
                    placeholder="Illimité"
                    onChange={onPlayersChange}
                    type="number"
                    slotProps={{
                        input: {
                            min: 1,
                            step: 1,
                        },
                    }}
                />
            </FormControl>
            <FormControl>
                <FormLabel>
                    <Typography
                        startDecorator={<CategoryIcon />}
                        textColor="inherit"
                    >
                        Catégorie
                    </Typography>
                </FormLabel>
                <Autocomplete<number>
                    placeholder="Aucune catégorie"
                    autoComplete
                    selectOnFocus
                    openOnFocus
                    clearOnEscape
                    autoHighlight
                    loading={!categories}
                    loadingText="Chargement des catégories..."
                    noOptionsText="Aucune catégorie correspondante"
                    clearText="Supprimer la catégorie sélectionnée"
                    openText=""
                    popupIcon={<UnfoldMoreIcon />}
                    value={data.categoryId || null}
                    filterOptions={(options, { inputValue }) => {
                        return categories
                            ? categories
                                  .filterByIds(options)
                                  .filterByText(inputValue)
                                  .categories.map((category) => category.id)
                            : [];
                    }}
                    options={(
                        categories?.orderByName().orderByUserId(false)
                            .categories || []
                    ).map((category) => category.id)}
                    groupBy={(option) => {
                        if (!categories) {
                            return '';
                        }

                        const userId = categories.findById(option)?.userId;

                        return userId
                            ? `Vos catégories`
                            : 'Les catégories publiques';
                    }}
                    getOptionLabel={(option) => {
                        if (!categories) {
                            return '';
                        }

                        return categories.findById(option)?.name || '';
                    }}
                    onChange={onCategoryChange}
                />
            </FormControl>
            <FormControl>
                <FormLabel>
                    <Typography
                        startDecorator={<PaletteIcon />}
                        textColor="inherit"
                    >
                        Thème
                    </Typography>
                </FormLabel>
                <Input
                    value={data.theme}
                    placeholder="Aucun thème"
                    onChange={onThemeChange}
                />
            </FormControl>
            <FormControl>
                <FormLabel>
                    <Typography
                        startDecorator={<InfoIcon />}
                        textColor="inherit"
                    >
                        Infos
                    </Typography>
                </FormLabel>
                <Textarea
                    value={data.infos}
                    placeholder="Aucune information supplémentaire"
                    onChange={onInfosChange}
                />
            </FormControl>
        </React.Fragment>
    );
};

export default Impro;
