import * as React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import fetchShow from '@Application/fetchShow';
import FetchShow from '@Application/Queries/FetchShow';
import { useDispatch, useWaitForState } from '@Stores/index';
import useShowsStore from '@Stores/Stores/shows';
import Loader from '@Guidelines/Loader';
import Page from '../Page';
import PageHead from '../PageHead';
import PageBody from '../PageBody';
import PageSide from '../PageSide';
import PageContent from '../PageContent';
import {
    Box,
    Button,
    ButtonGroup,
    Chip,
    IconButton,
    Stack,
    Tooltip,
} from '@mui/joy';
import {
    CalendarMonth as CalendarMonthIcon,
    Timelapse as TimelapseIcon,
    Add as AddIcon,
    PrintOutlined as PrintIcon,
} from '@mui/icons-material';
import ShowDetail from './Detail';
import ShowSchedule from './Schedule';
import AddStepModal from './AddStepModal';

type Props = {};

const Show: React.ComponentType<Props> = () => {
    const [isAddScheduleModalOpen, setIsAddScheduleModalOpen] =
        React.useState(false);

    const params = useParams();
    const intl = useIntl();
    const showsStore = useShowsStore();
    const dispatch = useDispatch();

    const expectedShowId = params.showId ? parseInt(params.showId, 10) : 0;

    const show = useWaitForState({
        selector: () => showsStore.show,
        check: (show) => !!show && show.id === expectedShowId,
        run: () => {
            fetchShow(new FetchShow({ id: expectedShowId })).then(dispatch);
        },
    });

    const onAddScheduleButtonClick = React.useCallback(() => {
        setIsAddScheduleModalOpen(true);
    }, []);

    const onAddScheduleModalClose = React.useCallback(() => {
        setIsAddScheduleModalOpen(false);
    }, []);

    if (!show) {
        return <Loader />;
    }

    const showRelativeDays = show.computeRelativeDays();
    const showDuration = show.computeDuration();

    return (
        <React.Fragment>
            <Page>
                <PageHead
                    title={
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            spacing={{
                                xs: 1,
                                md: 3,
                            }}
                            alignItems={{
                                xs: 'center',
                                sm: 'flex-start',
                                md: 'center',
                            }}
                        >
                            <Box>{show.name}</Box>
                            <Stack direction="row" spacing={1}>
                                <Chip
                                    variant="plain"
                                    color="neutral"
                                    size="lg"
                                    startDecorator={<CalendarMonthIcon />}
                                >
                                    {typeof showRelativeDays === 'number'
                                        ? intl.formatRelativeTime(
                                              showRelativeDays,
                                              'day',
                                              {
                                                  numeric: 'auto',
                                                  style: 'long',
                                              },
                                          )
                                        : '-'}
                                </Chip>
                                <Chip
                                    variant="plain"
                                    color="neutral"
                                    size="lg"
                                    startDecorator={<TimelapseIcon />}
                                >
                                    {typeof showDuration === 'number'
                                        ? intl.formatNumber(showDuration, {
                                              style: 'unit',
                                              unit: 'minute',
                                              unitDisplay: 'long',
                                          })
                                        : '-'}
                                </Chip>
                            </Stack>
                        </Stack>
                    }
                    breadcrumbs={[
                        { label: 'Spectacles', to: '/shows' },
                        { label: 'Spectacle' },
                    ]}
                >
                    <ButtonGroup spacing={1} size="lg">
                        <Button
                            variant="solid"
                            color="primary"
                            startDecorator={<AddIcon />}
                            onClick={onAddScheduleButtonClick}
                        >
                            Ajouter une étape
                        </Button>
                        <Tooltip title="Imprimer" arrow>
                            <IconButton
                                variant="solid"
                                component={RouterLink}
                                to={`/_/shows/${show.id}/print`}
                            >
                                <PrintIcon />
                            </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                </PageHead>
                <PageBody>
                    <PageSide>
                        <ShowDetail show={show} />
                    </PageSide>
                    <PageContent>
                        <ShowSchedule show={show} />
                    </PageContent>
                </PageBody>
            </Page>
            <AddStepModal
                show={show}
                isOpen={isAddScheduleModalOpen}
                onClose={onAddScheduleModalClose}
            />
        </React.Fragment>
    );
};

export default Show;
