import * as React from 'react';
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import Layout from './Layout';
import Home from './Home';
import Profile from './Profile';
import Shows from './Shows';
import Show from './Show';
import ShowPrint from './ShowPrint';
import fetchUser from '@Application/fetchUser';
import FetchUser from '@Application/Queries/FetchUser';
import { useDispatch } from '@Stores/index';
import useUserStore from '@Stores/Stores/user';

type Props = {};

const Private: React.ComponentType<Props> = () => {
    const [isLogged, setIsLogged] = React.useState<boolean | undefined>();

    const navigate = useNavigate();
    const userStore = useUserStore();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (userStore.user) {
            setIsLogged(true);

            return;
        }

        fetchUser(new FetchUser())
            .then(dispatch)
            .then(() => {
                setIsLogged(true);
            })
            .catch(() => {
                setIsLogged(false);
            });
    }, [dispatch, userStore.user]);

    React.useEffect(() => {
        if (isLogged === false) {
            navigate('/signin', { replace: true });
        }
    }, [isLogged, navigate]);

    if (isLogged !== true) {
        return null;
    }

    return (
        <Routes>
            <Route
                element={
                    <Layout>
                        <Outlet />
                    </Layout>
                }
            >
                <Route path={`/`} element={<Home />} />
                <Route path={`/profile`} element={<Profile />} />
                <Route path={`/shows`} element={<Shows />} />
                <Route path={`/shows/:showId`} element={<Show />} />
                <Route path={`/shows/:showId/print`} element={<ShowPrint />} />
            </Route>
        </Routes>
    );
};

export default Private;
