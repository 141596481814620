import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Canvg } from 'canvg';
import { Image } from '@react-pdf/renderer';

type Props = {
    icon: React.ReactNode;
    style?: Record<string, any>;
};

const SVGIcon: React.ComponentType<Props> = ({ icon, style }) => {
    const [uri, setUri] = React.useState<string | null | undefined>();

    const svgString = React.useMemo(() => {
        return ReactDOMServer.renderToStaticMarkup(icon);
    }, [icon]);

    React.useEffect(() => {
        try {
            const canvas: HTMLCanvasElement = document.createElement('canvas');
            canvas.setAttribute('width', '1000');
            canvas.setAttribute('height', '1000');
            const context = canvas.getContext('2d');

            if (!context) {
                throw new Error('Canvas context not found');
            }

            Canvg.fromString(context, svgString)
                .render()
                .then(() => {
                    setUri(canvas.toDataURL('image/png'));
                })
                .catch(() => {
                    setUri(null);
                });
        } catch (error) {
            return setUri(null);
        }
    }, [svgString]);

    if (!uri) return null;

    return <Image style={style} src={uri} />;
};

export default SVGIcon;
