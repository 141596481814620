import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UI from './UI';

const useStrictMode = false;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

const AppWrapper = useStrictMode ? React.StrictMode : React.Fragment;

root.render(
    <AppWrapper>
        <App>
            <UI />
        </App>
    </AppWrapper>,
);
