export type CreatePausePayload = {
    id: number;
    showId: number;
    duration: number;
    label: string;
};

export type CreatePauseSuccessAction = {
    type: 'CREATE_PAUSE_SUCCESS';
    payload: CreatePausePayload;
};

const createPauseSuccess = (
    payload: CreatePausePayload,
): CreatePauseSuccessAction => {
    return {
        type: 'CREATE_PAUSE_SUCCESS',
        payload: payload,
    };
};

export default createPauseSuccess;
