export type UserParameters = {
    id: number;
    email: string;
};

class User {
    id: number;
    email: string;

    constructor({ id, email }: UserParameters) {
        this.id = id;
        this.email = email;

        Object.freeze(this);
    }

    getUsername(): string {
        return this.email.split('@')[0].split('.')[0];
    }

    getInitials(): string {
        return this.email
            .split('@')
            .map((word) => word[0])
            .join('')
            .toUpperCase();
    }
}

export default User;
