import React from 'react';
import { View, Image, Text } from '@react-pdf/renderer';
import { useCloudinary } from '@Guidelines/Image';
import useStyles from './useStyles';
import ShowView from '@Stores/Views/Show';
import { useIntl } from 'react-intl';

type Props = {
    show: ShowView;
    fullWidth?: boolean;
};

const Back: React.ComponentType<Props> = ({ show, fullWidth }) => {
    const cloudinary = useCloudinary();
    const intl = useIntl();

    const styles = useStyles(({ theme, fontSize }) => ({
        root: {
            height: fullWidth ? '100%' : '50%',
            width: fullWidth ? '100%' : '50%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: theme.spacing(2),
        },
        image: {
            width: '150px',
            height: '150px',
        },
        title: {
            width: '100%',
            textAlign: 'center',
            padding: theme.spacing(1),
            fontFamily: 'Limelight',
            fontSize: fontSize(2),
            backgroundColor: theme.palette.primary[50],
            color: theme.palette.primary[900],
        },
        subtitle: {
            fontSize: fontSize(0.8),
            color: theme.palette.neutral[500],
        },
    }));

    return (
        <View style={styles.root}>
            <Image
                style={styles.image}
                src={cloudinary.image('/logo/notext-transparent-min.png')}
            />
            <Text style={styles.title}>{show.name}</Text>
            {show.date && (
                <Text style={styles.subtitle}>
                    {intl.formatDate(show.date, {
                        dateStyle: 'long',
                    })}
                </Text>
            )}
        </View>
    );
};

export default Back;
