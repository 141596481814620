import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/joy/CssBaseline';

import Providers from './Providers';

type Props = {
    children: React.ReactNode;
};

const App: React.ComponentType<Props> = ({ children }) => {
    return (
        <Providers global>
            <CssBaseline />
            <BrowserRouter basename="/">{children}</BrowserRouter>
        </Providers>
    );
};

export default App;
