import * as React from 'react';
import { Box, Typography, Stack, Divider } from '@mui/joy';
import makeSx from '@Guidelines/sx';
import ShowItem from './Item';
import ShowsView from '@Stores/Views/Shows';

const sx = makeSx({
    list: (theme) => ({
        position: 'relative',
        '&::before,&::after': {
            zIndex: 2,
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                width: theme.spacing(3),
            },
        },
        '&::before': {
            left: theme.spacing(-2),
            background:
                'linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
            [theme.breakpoints.up('md')]: {
                left: theme.spacing(-3),
            },
        },
        '&::after': {
            right: theme.spacing(-2),
            background:
                'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
            [theme.breakpoints.up('md')]: {
                right: theme.spacing(-3),
            },
        },
    }),
    shows: (theme) => ({
        zIndex: 1,
        margin: theme.spacing(0, -2),
        padding: theme.spacing(0, 2),
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(0, -3),
            padding: theme.spacing(0, 3),
        },
    }),
});

type Props = {
    title: React.ReactNode;
    shows: ShowsView;
};

const ShowsList: React.ComponentType<Props> = ({ title, shows }) => {
    if (shows.shows.length === 0) {
        return null;
    }

    return (
        <Stack direction="column" spacing={2}>
            <Box>
                <Typography level="h3" gutterBottom>
                    {title}
                </Typography>
                <Divider />
            </Box>
            <Box sx={sx.list}>
                <Stack
                    direction="row"
                    spacing={2}
                    overflow="auto"
                    sx={sx.shows}
                >
                    {shows.shows.map((show) => (
                        <ShowItem key={show.id} show={show} />
                    ))}
                </Stack>
            </Box>
        </Stack>
    );
};

export default ShowsList;
