import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import createSX from '@Guidelines/sx';
import Image, { useCloudinary } from '@Guidelines/Image';
import {
    Box,
    Card,
    DialogContent,
    Divider,
    Drawer,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemContent,
    ListItemDecorator,
    ModalClose,
    Sheet,
    Typography,
} from '@mui/joy';
import {
    TheaterComedy as TheaterComedyIcon,
    AccountCircle as AccountCircleIcon,
    Person as PersonIcon,
    Logout as LogoutIcon,
    Home as HomeIcon,
} from '@mui/icons-material';
import UserView from '@Stores/Views/User';

const sx = createSX({
    root: {
        '--Drawer-transitionDuration': '0.25s',
    },
    close: {
        background: 'transparent',
    },
    header: (theme) => ({
        height: '192px',
        boxSizing: 'content-box',
        background: 'linear-gradient(to bottom, #122B4A,#00162E)',
    }),
    logoLink: {
        height: '100%',
        width: '100%',
    },
    footer: (theme) => ({
        // padding: theme.spacing(2),
    }),
});

type Props = {
    isOpen: boolean;
    onClose: () => void;
    user?: UserView;
    onLogoutClick: () => void;
};

const Sidebar: React.ComponentType<Props> = ({
    user,
    isOpen,
    onClose,
    onLogoutClick,
}) => {
    const cloudinary = useCloudinary();

    return (
        <Drawer size="sm" open={isOpen} onClose={onClose} sx={sx.root}>
            <ModalClose size="lg" variant="solid" sx={sx.close} />
            <Sheet variant="soft" color="primary" sx={sx.header}>
                <Link
                    component={RouterLink}
                    to="/_"
                    sx={sx.logoLink}
                    onClick={onClose}
                >
                    <Image
                        alt="improkit logo"
                        src={cloudinary.image('/logo/transparent.png')}
                    />
                </Link>
            </Sheet>
            <Divider />
            <DialogContent>
                <Box>
                    <List size="lg">
                        <ListItem>
                            <ListItemButton
                                component={RouterLink}
                                to="/_"
                                onClick={onClose}
                            >
                                <ListItemDecorator>
                                    <HomeIcon />
                                </ListItemDecorator>
                                <ListItemContent>Accueil</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                component={RouterLink}
                                to="/_/shows"
                                onClick={onClose}
                            >
                                <ListItemDecorator>
                                    <TheaterComedyIcon />
                                </ListItemDecorator>
                                <ListItemContent>
                                    Mes spectacles
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </DialogContent>
            <Divider />
            <Sheet sx={sx.footer}>
                {user && (
                    <Card size="sm" variant="soft" color="neutral">
                        <Typography
                            level="body-sm"
                            justifyContent="center"
                            startDecorator={<AccountCircleIcon />}
                        >
                            {user.email}
                        </Typography>
                    </Card>
                )}
                <List>
                    <ListItem>
                        <ListItemButton
                            component={RouterLink}
                            to="/_/profile"
                            onClick={onClose}
                        >
                            <ListItemDecorator>
                                <PersonIcon />
                            </ListItemDecorator>
                            <ListItemContent>Mon profil</ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            onClick={onLogoutClick}
                            variant="soft"
                            color="danger"
                        >
                            <ListItemDecorator>
                                <LogoutIcon />
                            </ListItemDecorator>
                            <ListItemContent>Déconnexion</ListItemContent>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Sheet>
        </Drawer>
    );
};

export default Sidebar;
