import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Card,
    CardContent,
    Typography,
    Link,
    List,
    ListItem,
    ListItemDecorator,
    ListItemContent,
    CardCover,
    Stack,
} from '@mui/joy';
import {
    CalendarMonth as CalendarMonthIcon,
    Timelapse as TimelapseIcon,
} from '@mui/icons-material';
import Image, { useCloudinary } from '@Guidelines/Image';
import makeSx from '@Guidelines/sx';
import { useIntl } from 'react-intl';
import ShowView from '@Stores/Views/Show';

const imageDimensions = {
    width: '325px',
    ratio: 16 / 6,
};

const sx = makeSx({
    card: (theme) => ({
        flexShrink: 0,
        transition: 'all 0.25s',
        width: imageDimensions.width,
        maxWidth: '80%',
    }),
    cardImageContainer: {
        overflow: 'hidden',
        '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            inset: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
        },
    },
    cardContent: {
        height: `calc(${imageDimensions.width} / ${imageDimensions.ratio})`,
    },
    infos: (theme) => ({
        borderRadius: theme.radius.sm,
    }),
});

type Props = {
    show: ShowView;
};

const ShowsList: React.ComponentType<Props> = ({ show }) => {
    const cloudinary = useCloudinary();
    const intl = useIntl();

    const showDuration = show.computeDuration();

    return (
        <Card key={show.id} sx={sx.card} variant="solid" invertedColors>
            <CardCover sx={sx.cardImageContainer}>
                <Image
                    src={cloudinary.image('theater-min.jpg')}
                    alt="show image"
                    cover
                />
            </CardCover>
            <CardContent>
                <Stack
                    sx={sx.cardContent}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography level="title-lg" textAlign="center">
                        <Link
                            overlay
                            component={RouterLink}
                            to={`/_/shows/${show.id}`}
                            textColor="inherit"
                        >
                            {show.name}
                        </Link>
                    </Typography>
                </Stack>
            </CardContent>
            <CardContent data-skip-inverted-colors>
                <List size="sm" variant="soft" color="neutral" sx={sx.infos}>
                    <ListItem>
                        <ListItemDecorator>
                            <CalendarMonthIcon />
                        </ListItemDecorator>
                        <ListItemContent>
                            {show.date
                                ? intl.formatDate(show.date, {
                                      dateStyle: 'full',
                                      timeStyle: 'short',
                                  })
                                : ''}
                        </ListItemContent>
                    </ListItem>
                    <ListItem>
                        <ListItemDecorator>
                            <TimelapseIcon />
                        </ListItemDecorator>
                        <ListItemContent>
                            {typeof showDuration === 'number'
                                ? intl.formatNumber(showDuration, {
                                      style: 'unit',
                                      unit: 'minute',
                                      unitDisplay: 'long',
                                  })
                                : ''}
                        </ListItemContent>
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
};

export default ShowsList;
