import * as React from 'react';
import { useParams } from 'react-router-dom';
import fetchShow from '@Application/fetchShow';
import FetchShow from '@Application/Queries/FetchShow';
import { useDispatch, useWaitForState } from '@Stores/index';
import useShowsStore from '@Stores/Stores/shows';
import useUserStore from '@Stores/Stores/user';
import Loader from '@Guidelines/Loader';
import Page from '../Page';
import PageHead from '../PageHead';
import PageBody from '../PageBody';
import Viewer from './Viewer';
import PDF from './PDF';
import { Button } from '@mui/joy';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Download as DownloadIcon } from '@mui/icons-material';
import fetchUser from '@Application/fetchUser';
import FetchUser from '@Application/Queries/FetchUser';

type Props = {};

const ShowPrint: React.ComponentType<Props> = () => {
    const params = useParams();
    const showsStore = useShowsStore();
    const userStore = useUserStore();
    const dispatch = useDispatch();

    const expectedShowId = params.showId ? parseInt(params.showId, 10) : 0;

    const user = useWaitForState({
        selector: () => userStore.user,
        check: (user) => !!user,
        run: () => {
            fetchUser(new FetchUser()).then(dispatch);
        },
    });

    const show = useWaitForState({
        selector: () => showsStore.show,
        check: (show) => !!show && show.id === expectedShowId,
        run: () => {
            fetchShow(new FetchShow({ id: expectedShowId })).then(dispatch);
        },
    });

    if (!user || !show) {
        return <Loader />;
    }

    const fileName = `${show.name}.pdf`;

    return (
        <React.Fragment>
            <Page>
                <PageHead
                    title={`Impression de ${show.name}`}
                    breadcrumbs={[
                        { label: 'Spectacles', to: '/shows' },
                        { label: 'Spectacle', to: `/shows/${show.id}` },
                        { label: 'Impression' },
                    ]}
                >
                    <Button
                        size="lg"
                        startDecorator={<DownloadIcon />}
                        component={PDFDownloadLink}
                        document={
                            <PDF show={show} user={user} fileName={fileName} />
                        }
                        fileName={fileName}
                    >
                        Télécharger le PDF
                    </Button>
                </PageHead>
                <PageBody>
                    <Viewer show={show} user={user} fileName={fileName} />
                </PageBody>
            </Page>
        </React.Fragment>
    );
};

export default ShowPrint;
