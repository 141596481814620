import Impros from './Impros';
import Impro, { type ImproParameters } from './Impro';
import Talks from './Talks';
import Talk, { type TalkParameters } from './Talk';
import Pauses from './Pauses';
import Pause, { type PauseParameters } from './Pause';
import Schedules from './Schedules';
import Schedule, { type ScheduleParameters } from './Schedule';

export type ShowParameters = {
    id: number;
    name: string;
    date: Date | null;
    impros: Array<ImproParameters>;
    talks: Array<TalkParameters>;
    pauses: Array<PauseParameters>;
    schedules: Array<ScheduleParameters>;
};

export type ShowStep = Impro | Talk | Pause;

class Show {
    id: number;
    name: string;
    date: Date | null;
    impros: Impros;
    talks: Talks;
    pauses: Pauses;
    schedules: Schedules;

    constructor({
        id,
        name,
        date,
        impros,
        talks,
        pauses,
        schedules,
    }: ShowParameters) {
        this.id = id;
        this.name = name;
        this.date = date;
        this.impros = new Impros(impros.map((impro) => new Impro(impro)));
        this.talks = new Talks(talks.map((talk) => new Talk(talk)));
        this.pauses = new Pauses(pauses.map((pause) => new Pause(pause)));
        this.schedules = new Schedules(
            schedules.map((schedule) => new Schedule(schedule)),
        );

        Object.freeze(this);
    }

    computeRelativeDays() {
        const { date } = this;

        if (!date) return null;

        return Math.round(
            (date.getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24,
        );
    }

    computeDuration(): number | null {
        const { schedules } = this;

        if (schedules.schedules.length === 0) return null;

        return schedules.schedules.reduce((acc, schedule) => {
            const item = this.findStep(schedule);

            if (!item) return acc;

            return acc + item.computeDuration();
        }, 0);
    }

    findStep(schedule: Schedule): ShowStep {
        const { impros, talks, pauses } = this;
        const { itemType, itemId } = schedule;

        switch (itemType) {
            case 'IMPRO':
                return impros.findById(itemId);
            case 'TALK':
                return talks.findById(itemId);
            case 'PAUSE':
                return pauses.findById(itemId);
        }

        throw new Error(`Item ${itemId} with type ${itemType} not found`);
    }

    findSchedule(step: ShowStep): Schedule | void {
        const schedules = this.schedules.findByItem(
            step.getScheduleType(),
            step.id,
        );

        return schedules.schedules[0];
    }

    findUsedSteps(): Array<ShowStep> {
        return this.schedules.schedules.map((schedule) =>
            this.findStep(schedule),
        );
    }

    findUnusedSteps(): Array<ShowStep> {
        const { impros, talks, pauses, schedules } = this;

        const unusedImpros = impros.impros.filter(
            (impro) => !schedules.hasItem('IMPRO', impro.id),
        );
        const unusedtTalks = talks.talks.filter(
            (talk) => !schedules.hasItem('TALK', talk.id),
        );
        const unusedPauses = pauses.pauses.filter(
            (pause) => !schedules.hasItem('PAUSE', pause.id),
        );

        return [...unusedImpros, ...unusedtTalks, ...unusedPauses];
    }

    addImpro(impro: ImproParameters): Show {
        return new Show({
            ...this,
            schedules: [...this.schedules.schedules],
            pauses: [...this.pauses.pauses],
            talks: [...this.talks.talks],
            impros: [...this.impros.addImpro(new Impro(impro)).impros],
        });
    }

    updateImpro(impro: ImproParameters): Show {
        return new Show({
            ...this,
            schedules: [...this.schedules.schedules],
            pauses: [...this.pauses.pauses],
            talks: [...this.talks.talks],
            impros: [...this.impros.updateImpro(new Impro(impro)).impros],
        });
    }

    removeImpro(id: number): Show {
        return new Show({
            ...this,
            schedules: [...this.schedules.schedules],
            pauses: [...this.pauses.pauses],
            talks: [...this.talks.talks],
            impros: [...this.impros.removeImpro(id).impros],
        });
    }

    addTalk(talk: TalkParameters): Show {
        return new Show({
            ...this,
            schedules: [...this.schedules.schedules],
            pauses: [...this.pauses.pauses],
            talks: [...this.talks.addTalk(new Talk(talk)).talks],
            impros: [...this.impros.impros],
        });
    }

    updateTalk(talk: TalkParameters): Show {
        return new Show({
            ...this,
            schedules: [...this.schedules.schedules],
            pauses: [...this.pauses.pauses],
            talks: [...this.talks.updateTalk(new Talk(talk)).talks],
            impros: [...this.impros.impros],
        });
    }

    removeTalk(id: number): Show {
        return new Show({
            ...this,
            schedules: [...this.schedules.schedules],
            pauses: [...this.pauses.pauses],
            talks: [...this.talks.removeTalk(id).talks],
            impros: [...this.impros.impros],
        });
    }

    addPause(pause: PauseParameters): Show {
        return new Show({
            ...this,
            schedules: [...this.schedules.schedules],
            pauses: [...this.pauses.addPause(new Pause(pause)).pauses],
            talks: [...this.talks.talks],
            impros: [...this.impros.impros],
        });
    }

    updatePause(pause: PauseParameters): Show {
        return new Show({
            ...this,
            schedules: [...this.schedules.schedules],
            pauses: [...this.pauses.updatePause(new Pause(pause)).pauses],
            talks: [...this.talks.talks],
            impros: [...this.impros.impros],
        });
    }

    removePause(id: number): Show {
        return new Show({
            ...this,
            schedules: [...this.schedules.schedules],
            pauses: [...this.pauses.removePause(id).pauses],
            talks: [...this.talks.talks],
            impros: [...this.impros.impros],
        });
    }

    replaceSchedules(schedules: Array<ScheduleParameters>): Show {
        return new Show({
            ...this,
            schedules: schedules.map((schedule) => new Schedule(schedule)),
            pauses: [...this.pauses.pauses],
            talks: [...this.talks.talks],
            impros: [...this.impros.impros],
        });
    }
}

export default Show;
