import * as Api from '../Domain/Api';
import CreatePause from './Commands/CreatePause';
import createPauseSuccess, {
    type CreatePausePayload,
} from './Actions/createPauseSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const createPause = async (command: CreatePause) => {
    const payload = await Api.post<CreatePausePayload>(
        `${parameters.api.host}/pauses`,
        {
            showId: command.showId,
            duration: command.duration,
            label: command.label,
        },
        {
            jwt: JWTStorer.get(),
        },
    );

    return createPauseSuccess(payload);
};

export default createPause;
