import * as React from 'react';
import { FormControl, FormLabel, Input, Typography } from '@mui/joy';
import { Timer as TimerIcon, Label as LabelIcon } from '@mui/icons-material';
import { useIntl } from 'react-intl';

export type Data = {
    duration: string;
    label: string;
};

type Props = {
    data: Data;
    onChange: (data: Data) => void;
};

const Pause: React.ComponentType<Props> = ({ data, onChange }) => {
    const intl = useIntl();

    const onDurationChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
                ...data,
                duration: e.target.value,
            });
        },
        [data, onChange],
    );

    const onLabelChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
                ...data,
                label: e.target.value,
            });
        },
        [data, onChange],
    );

    const findDurationUnit = React.useCallback(
        (duration: string) => {
            return intl
                .formatNumberToParts(parseInt(duration), {
                    style: 'unit',
                    unit: 'minute',
                    unitDisplay: 'short',
                })
                .find(({ type }) => type === 'unit')?.value;
        },
        [intl],
    );

    return (
        <React.Fragment>
            <FormControl required>
                <FormLabel>
                    <Typography
                        startDecorator={<LabelIcon />}
                        textColor="inherit"
                    >
                        Libellé
                    </Typography>
                </FormLabel>
                <Input
                    value={data.label}
                    onChange={onLabelChange}
                    type="text"
                    slotProps={{
                        input: {
                            maxLength: 255,
                        },
                    }}
                />
            </FormControl>
            <FormControl required orientation="horizontal">
                <FormLabel sx={{ flex: '1 1 100%' }}>
                    <Typography
                        startDecorator={<TimerIcon />}
                        textColor="inherit"
                    >
                        Durée
                    </Typography>
                </FormLabel>
                <Input
                    sx={{ width: '250px' }}
                    value={data.duration}
                    onChange={onDurationChange}
                    type="number"
                    slotProps={{
                        input: {
                            min: 1,
                            step: 1,
                        },
                    }}
                    endDecorator={findDurationUnit(data.duration)}
                />
            </FormControl>
        </React.Fragment>
    );
};

export default Pause;
