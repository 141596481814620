import * as Api from '../Domain/Api';
import FetchUser from './Queries/FetchUser';
import fetchUserSuccess, {
    type FetchUserPayload,
    type FetchUserSuccessAction,
} from './Actions/fetchUserSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const fetchUser = async (query: FetchUser): Promise<FetchUserSuccessAction> => {
    const { sub: userId } = JWTStorer.decode();

    const payload = await Api.get<FetchUserPayload>(
        `${parameters.api.host}/users/${userId}`,
        {
            jwt: JWTStorer.get(),
        },
    );

    return fetchUserSuccess(payload);
};

export default fetchUser;
