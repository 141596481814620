import * as Api from '../Domain/Api';
import CreateTalk from './Commands/CreateTalk';
import createTalkSuccess, {
    type CreateTalkPayload,
} from './Actions/createTalkSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const createTalk = async (command: CreateTalk) => {
    const payload = await Api.post<CreateTalkPayload>(
        `${parameters.api.host}/talks`,
        {
            showId: command.showId,
            duration: command.duration,
            label: command.label,
        },
        {
            jwt: JWTStorer.get(),
        },
    );

    return createTalkSuccess(payload);
};

export default createTalk;
