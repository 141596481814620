import * as Api from '../Domain/Api';
import UpdateSchedulePosition from './Commands/UpdateSchedulePosition';
import updateSchedulePositionSuccess, {
    type UpdateSchedulePositionPayload,
} from './Actions/updateSchedulePositionSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const updateSchedulePosition = async (command: UpdateSchedulePosition) => {
    const payload = await Api.patch<UpdateSchedulePositionPayload>(
        `${parameters.api.host}/shows/${command.showId}/schedules/${command.id}`,
        {
            position: command.position,
        },
        {
            jwt: JWTStorer.get(),
        },
    );

    return updateSchedulePositionSuccess(payload);
};

export default updateSchedulePosition;
