import * as Api from '../Domain/Api';
import DeletePause from './Commands/DeletePause';
import deletePauseSuccess, {
    type DeletePausePayload,
} from './Actions/deletePauseSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const deletePause = async (command: DeletePause) => {
    const payload = await Api.del<DeletePausePayload>(
        `${parameters.api.host}/pauses/${command.id}`,
        {
            jwt: JWTStorer.get(),
        },
    );

    return deletePauseSuccess(payload);
};

export default deletePause;
