import memoize from 'memoizee';

const run = fetch;

const memoizedRun = memoize(
    async (...args: Parameters<typeof run>) => {
        return run(...args).then((response) => {
            memoizedRun.delete(...args);
            return response;
        });
    },
    { promise: true, normalizer: JSON.stringify },
);

const concurrencyRun = (...args: Parameters<typeof memoizedRun>) =>
    memoizedRun(...args).then((response) => response.clone());

export { concurrencyRun as concurrencyFetch, run as fetch };
