import * as React from 'react';
import { type SigninUserSuccessAction } from '@Application/Actions/signinUserSuccess';
import { type SignoutUserSuccessAction } from '@Application/Actions/signoutUserSuccess';
import { type FetchCategoriesSuccessAction } from '@Application/Actions/fetchCategoriesSuccess';
import CategoryView from '../Views/Category';
import CategoriesView from '../Views/Categories';
import register from '../register';

type Action =
    | SigninUserSuccessAction
    | SignoutUserSuccessAction
    | FetchCategoriesSuccessAction;

type State = {
    categories?: CategoriesView;
};

const initialState: State = {};

const store: React.Reducer<State, Action> = (state, action): State => {
    switch (action.type) {
        case 'FETCH_CATEGORIES_SUCCESS': {
            return {
                ...state,
                categories: new CategoriesView(
                    action.payload.map((item) => new CategoryView(item)),
                ),
            };
        }
        case 'SIGNIN_USER_SUCCESS': {
            return initialState;
        }
        case 'SIGNOUT_USER_SUCCESS': {
            return initialState;
        }
        default:
            return state;
    }
};

const useStore = register('categories', store, initialState);

export default useStore;
