import Talk from './Talk';

class Talks {
    talks: Array<Talk>;

    constructor(talks: Array<Talk>) {
        this.talks = talks;

        Object.freeze(this);
    }

    findById(id: number): Talk {
        const talk = this.talks.find((talk) => talk.id === id);

        if (!talk) {
            throw new Error(`Talk with id ${id} not found`);
        }

        return talk;
    }

    addTalk(talk: Talk): Talks {
        return new Talks(this.talks.concat([talk]));
    }

    updateTalk(talk: Talk): Talks {
        return new Talks(
            this.talks.map((existingTalk) =>
                existingTalk.id === talk.id ? talk : existingTalk,
            ),
        );
    }

    removeTalk(id: number): Talks {
        return new Talks(this.talks.filter((talk) => talk.id !== id));
    }
}

export default Talks;
