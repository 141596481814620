import * as React from 'react';
import {
    Stack,
    LinearProgress,
    Typography,
    type ColorPaletteProp,
} from '@mui/joy';
import { passwordStrengths } from './computePasswordStrength';

type Status = {
    color: ColorPaletteProp;
    text: string;
};

type Props = {
    strength: number;
};

const PasswordStrength: React.ComponentType<Props> = ({ strength }) => {
    const { color, text }: Status = React.useMemo(() => {
        if (strength >= passwordStrengths.VERY_STRONG) {
            return {
                color: 'success',
                text: 'Très fort',
            };
        }

        if (strength >= passwordStrengths.STRONG) {
            return {
                color: 'primary',
                text: 'Fort',
            };
        }

        if (strength >= passwordStrengths.WEAK) {
            return {
                color: 'warning',
                text: 'Faible',
            };
        }

        if (strength >= passwordStrengths.VERY_WEAK) {
            return {
                color: 'danger',
                text: 'Très faible',
            };
        }

        return {
            color: 'neutral',
            text: 'Insuffisant',
        };
    }, [strength]);

    return (
        <Stack spacing={0.5}>
            <LinearProgress
                determinate
                size="sm"
                value={Math.min(strength, 100)}
                color={color}
                variant="solid"
            />
            <Typography
                level="body-xs"
                color={color}
                sx={{
                    alignSelf: 'flex-end',
                }}
            >
                {text}
            </Typography>
        </Stack>
    );
};

export default PasswordStrength;
