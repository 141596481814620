import * as React from 'react';
import Hero from './Hero';

type Props = {};

const Home: React.ComponentType<Props> = () => {
    return <Hero />;
};

export default Home;
