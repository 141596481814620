import * as React from 'react';
import useStore from './useStore';
import Context from './Context';
import debug from '@Guidelines/debug';

const debugStore = debug('Store');

type Props = {
    children: React.ReactNode;
};

const Store: React.ComponentType<Props> = ({ children }) => {
    const [state, dispatch] = useStore();

    debugStore(state);

    return (
        <Context.Provider
            value={{
                dispatch,
                state,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default Store;
