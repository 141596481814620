import { Theme } from '@mui/joy/styles/types/theme';
import { SystemStyleObject } from '@mui/system/styleFunctionSx';

type SxDeclaration =
    | SystemStyleObject<Theme>
    | ((theme: Theme) => SystemStyleObject<Theme>);

const makeSx = <T extends string>(input: Record<T, SxDeclaration>) => {
    return input;
};

const combineSX = (
    ...sxArray: Array<SxDeclaration | null | undefined | void>
): Array<SxDeclaration> => {
    return sxArray.reduce((acc: Array<SxDeclaration>, sxItem) => {
        if (!sxItem) return acc;

        return [...acc, sxItem];
    }, []);
};

export default makeSx;
export { makeSx, combineSX };

export type { SxDeclaration };
