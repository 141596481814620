type CreateScheduleOptions = {
    showId: number;
    itemId: number;
    itemType: string;
    position: number | null;
};

class CreateSchedule {
    showId: number;
    itemId: number;
    itemType: string;
    position: number | null;

    constructor({ showId, itemId, itemType, position }: CreateScheduleOptions) {
        this.showId = showId;
        this.itemId = itemId;
        this.itemType = itemType;
        this.position = position;

        Object.freeze(this);
    }
}

export default CreateSchedule;
