export type FetchShowPayload = {
    id: number;
    name: string;
    date: string | null;
    impros: Array<{
        id: number;
        duration: number;
        presentation: number;
        debrief: number;
        players: number | null;
        theme: string | null;
        infos: string | null;
        category: {
            id: number;
            name: string;
            userId: number | null;
        };
        type: string;
    }>;
    talks: Array<{
        id: number;
        duration: number;
        label: string;
    }>;
    pauses: Array<{
        id: number;
        duration: number;
        label: string;
    }>;
    schedules: Array<{
        id: number;
        position: number;
        itemType: string;
        itemId: number;
    }>;
};

type ComputedFetchShowPayload = Omit<FetchShowPayload, 'date'> & {
    date: Date | null;
};

const computePayload = (
    payload: FetchShowPayload,
): ComputedFetchShowPayload => {
    return {
        ...payload,
        date: payload.date ? new Date(payload.date) : null,
    };
};

export type FetchShowSuccessAction = {
    type: 'FETCH_SHOW_SUCCESS';
    payload: ComputedFetchShowPayload;
};

const fetchShowSuccess = (
    payload: FetchShowPayload,
): FetchShowSuccessAction => {
    return {
        type: 'FETCH_SHOW_SUCCESS',
        payload: computePayload(payload),
    };
};

export default fetchShowSuccess;
