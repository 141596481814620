type SignupUserOptions = {
    email: string;
    password: string;
};

class SignupUser {
    email: string;
    password: string;

    constructor({ email, password }: SignupUserOptions) {
        this.email = email;
        this.password = password;

        Object.freeze(this);
    }
}

export default SignupUser;
