import * as React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import ShowView from '@Stores/Views/Show';
import UserView from '@Stores/Views/User';
import PDF from './PDF';
import Loader from '@Guidelines/Loader';

type Props = {
    show: ShowView;
    user: UserView;
    fileName: string;
};

const Viewer: React.ComponentType<Props> = ({ show, user, fileName }) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setIsLoading(true);
    }, []);

    const onPdfRender = React.useCallback(() => {
        setIsLoading(false);
    }, []);

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
                <PDF
                    fileName={fileName}
                    show={show}
                    user={user}
                    onRender={onPdfRender}
                />
            </PDFViewer>
        </React.Fragment>
    );
};

export default Viewer;
