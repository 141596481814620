import * as Api from '../Domain/Api';
import UpdateImpro from './Commands/UpdateImpro';
import updateImproSuccess, {
    type UpdateImproPayload,
} from './Actions/updateImproSuccess';
import * as JWTStorer from '../Domain/JWTStorer';
import parameters from '../parameters';

const updateImpro = async (command: UpdateImpro) => {
    const payload = await Api.patch<UpdateImproPayload>(
        `${parameters.api.host}/impros/${command.id}`,
        {
            duration: command.duration,
            presentation: command.presentation,
            debrief: command.debrief,
            players: command.players,
            type: command.type,
            theme: command.theme,
            infos: command.infos,
            categoryId: command.categoryId,
        },
        {
            jwt: JWTStorer.get(),
        },
    );

    return updateImproSuccess(payload);
};

export default updateImpro;
