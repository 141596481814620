import * as React from 'react';
import ShowView from '@Stores/Views/Show';
import { View, Text } from '@react-pdf/renderer';
import useStyles from './useStyles';
import ImproView from '@Stores/Views/Impro';
import { useIntl } from 'react-intl';

type Props = {
    show: ShowView;
};

const Schedule: React.ComponentType<Props> = ({ show }) => {
    const intl = useIntl();

    const styles = useStyles(({ theme, fontSize }) => ({
        root: {
            flexDirection: 'column',
            width: '100%',
            fontSize: fontSize(0.8),
        },
        row: {
            flexDirection: 'row',
            borderBottom: `1px solid ${theme.palette.neutral[300]}`,
        },
        rowHeader: {
            backgroundColor: theme.palette.neutral[800],
            color: theme.palette.neutral[50],
            fontSize: fontSize(0.7),
        },
        rowIMPRO: {},
        rowPAUSE: {
            backgroundColor: theme.palette.neutral[400],
            color: theme.palette.neutral[50],
        },
        rowTALK: {
            backgroundColor: theme.palette.primary[100],
            color: theme.palette.primary[800],
        },
        case: {
            padding: theme.spacing(0.5),
            margin: theme.spacing(0.5),
            flex: '1 0 auto',
            flexDirection: 'row',
            alignItems: 'center',
        },
        casePosition: {
            width: '25px',
            flex: '0 0 auto',
            justifyContent: 'flex-end',
            fontFamily: 'Limelight',
        },
        caseType: {
            width: '100px',
            flex: '0 0 auto',
            justifyContent: 'center',
        },
        caseTypeBadge: {
            padding: theme.spacing(0.5, 1),
            borderRadius: theme.radius.xs,
            backgroundColor: theme.palette.neutral[100],
            color: theme.palette.neutral[600],
        },
        caseTypeBadgeWARMUP: {
            backgroundColor: theme.palette.primary[100],
            color: theme.palette.primary[600],
        },
        caseTypeBadgeMIXED: {
            backgroundColor: theme.palette.success[100],
            color: theme.palette.success[600],
        },
        caseTypeBadgeCOMPARATIVE: {
            backgroundColor: theme.palette.warning[100],
            color: theme.palette.warning[600],
        },
        caseCategory: {
            width: '200px',
            flex: '0 0 auto',
        },
        caseTheme: {},
        caseLabel: {
            justifyContent: 'center',
        },
        caseDuration: {
            width: '50px',
            flex: '0 0 auto',
            justifyContent: 'flex-end',
        },
    }));

    const renderImproTypeLabel = (impro: ImproView) => {
        switch (impro.type) {
            case 'WARMUP':
                return 'Warmup';
            case 'MIXED':
                return 'Mixte';
            case 'COMPARATIVE':
                return 'Comparée';
            default:
                return impro.type;
        }
    };

    return (
        <View style={styles.root}>
            <View style={[styles.row, styles.rowHeader]}>
                <View style={[styles.case, styles.casePosition]}>
                    <Text>#</Text>
                </View>
                <View style={[styles.case, styles.caseType]}>
                    <Text>Type</Text>
                </View>
                <View style={[styles.case, styles.caseCategory]}>
                    <Text>Catégorie</Text>
                </View>
                <View style={[styles.case, styles.caseTheme]}>
                    <Text>Thème</Text>
                </View>
                <View style={[styles.case, styles.caseDuration]}>
                    <Text>Durée</Text>
                </View>
            </View>
            {show.schedules.orderByPosition().schedules.map((schedule) => {
                const step = show.findStep(schedule);

                return (
                    <View
                        style={[
                            styles.row,
                            styles[`row${schedule.itemType}` as 'row'],
                        ]}
                    >
                        {step instanceof ImproView ? (
                            <React.Fragment>
                                <View
                                    style={[styles.case, styles.casePosition]}
                                >
                                    <Text>
                                        {show.schedules
                                            .filterByType(
                                                step.getScheduleType(),
                                            )
                                            .orderByPosition()
                                            .schedules.indexOf(schedule) + 1}
                                    </Text>
                                </View>
                                <View style={[styles.case, styles.caseType]}>
                                    <Text
                                        style={[
                                            styles.caseTypeBadge,
                                            styles[
                                                `caseTypeBadge${step.type}` as 'caseTypeBadge'
                                            ],
                                        ]}
                                    >
                                        {renderImproTypeLabel(step)}
                                    </Text>
                                </View>
                                <View
                                    style={[styles.case, styles.caseCategory]}
                                >
                                    <Text>{step.category?.name}</Text>
                                </View>
                                <View style={[styles.case, styles.caseTheme]}>
                                    <Text>{step.theme}</Text>
                                </View>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <View style={[styles.case, styles.caseLabel]}>
                                    <Text>{step.label}</Text>
                                </View>
                            </React.Fragment>
                        )}
                        <View style={[styles.case, styles.caseDuration]}>
                            <Text>
                                {intl.formatNumber(step.duration, {
                                    style: 'unit',
                                    unit: 'minute',
                                    unitDisplay: 'short',
                                })}
                            </Text>
                        </View>
                    </View>
                );
            })}
        </View>
    );
};

export default Schedule;
